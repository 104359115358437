import { API } from '../../backend'

export const signup = (user) => {
  return fetch(`${API}/signup`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((resp) => {
      return resp.json()
    })
    .catch((err) => console.log(err))
}

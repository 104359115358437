import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import axios from 'axios'
import { API } from '../../backend'
import swal from 'sweetalert'

const Guests = () => {
  var db = firebase.firestore()
  const [guests, setGuests] = useState([])
  const [qoute, setQoute] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    const fetchGuestsOrders = async () => {
      var guestsDocs = await db.collection('newguestorders').get()
      setGuests(guestsDocs.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    fetchGuestsOrders()
  }, [reload])

  const handleQoute = (e) => {
    console.log(e.target.value)
    setQoute(e.target.value)
  }

  console.log(guests)

  const errorMessage = () => {
    return error ? (
      <div className="container">
        <div className="alert alert-dange">
          Sorry, failed to send the qoutation!
        </div>
      </div>
    ) : (
      <></>
    )
  }

  const loadingMessage = () => {
    return (
      loading && (
        <center style={{ textAlign: 'center', width: '100%' }}>
          <div className="spinner-border text-dark" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </center>
      )
    )
  }

  const submitQouteToGuest = async (e, guestOrder) => {
    e.preventDefault()
    setLoading(true)
    guestOrder.qoute = qoute
    console.log(guestOrder)
    await axios({
      method: 'post',
      url: `${API}/respond/guest/qoute`,
      data: guestOrder,
      headers: { Accept: 'application/json' },
    })
      .then((response) => {
        console.log(response)
        if (response.error) {
          setLoading(false)
          setError(true)
          swal('Could not send qoute', '', 'error')
        } else {
          setReload(!reload)
          setLoading(false)
          swal('Qoute submitted Successfully', '', 'success')
        }
      })
      .catch((err) => {
        setLoading(false)
        swal('Could not send qoute', '', 'error')
      })
  }
  return (
    <div>
      <br />
      {loadingMessage()}
      {errorMessage()}
      {guests === undefined ? (
        <center>Wait...</center>
      ) : guests.length === 0 ? (
        <center>No orders.</center>
      ) : (
        guests.map((guestOrder) => (
          <div className="card mb-3">
            <div className="row no-gutters">
              <div className="col-md-3">
                <img src={guestOrder.photoURL} className="card-img" alt="..." />
              </div>
              <div className="col-md-6">
                <div className="card-body">
                  <h5 className="card-title">{guestOrder.guestName}</h5>
                  <p className="card-text">
                    <strong> Product:</strong> {guestOrder.itemName.join(' ')}
                  </p>
                  <p className="card-text">
                    <strong>Quantity:</strong> {guestOrder.quantity}
                  </p>
                  {/* <p className="card-text">Quantity: { guestOrder.quantity }</p> */}
                  <p className="card-text">
                    <strong>SKU:</strong> {guestOrder.description}
                  </p>
                  <p className="card-text">
                    <strong>Brand:</strong> {guestOrder.brand}
                  </p>
                  {/* <p className="card-text">Quantity: {guestOrder.quantity}</p> */}
                </div>
              </div>
              <div className="col-md-3">
                <div class="input-group mb-3 mt-5">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Rs </span>
                  </div>
                  <input
                    onChange={handleQoute}
                    type="text"
                    class="form-control"
                    aria-label="Amount (to the nearest rupees)"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">.00</span>
                  </div>
                </div>
                <button
                  onClick={(e) => {
                    submitQouteToGuest(e, guestOrder)
                  }}
                  className="btn btn-dark text-white bg-dark"
                >
                  SUBMIT QOUTE
                </button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default Guests

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../firebase'

const ManageOfferAlert = () => {
  const [name, setName] = useState()
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [loading, setLoading] = useState(false)
  var db = firebase.firestore()

  const successMessage = () => {
    if (success) {
      return (
        <div className="alert alert-success mt-2">
          Offer created Successfully
        </div>
      )
    }
  }

  const loadingMessage = () => {
    return (
      loading && (
        <div className="container">
          <center style={{ textAlign: 'center', width: '100%' }}>
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        </div>
      )
    )
  }

  const errorMessage = () => {
    if (error) {
      return (
        <div className="alert alert-danger mt-2">Brand creation FAILED</div>
      )
    }
  }

  const handleChange = (event) => {
    setError('')
    setName(event.target.value)
  }

  const goBack = () => {
    return (
      <Link to="/admin/dashboard" className="btn btn-success">
        Go to Dashboard
      </Link>
    )
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setError('')
    setSuccess(false)
    setLoading(true)

    //req
    await db
      .collection('offers')
      .doc('offer')
      .set({
        name: name,
      })
      .then(() => {
        setName('')
        setLoading(false)
        setSuccess(true)
      })
      .catch((err) => {
        setLoading(false)
        setError(true)
      })
  }

  const ManageOfferAlertForm = () => {
    return (
      <form>
        <div className="form-group">
          <div className="lead">Enter the Offer</div>
          <input
            type="text"
            className="form-control my-3"
            onChange={handleChange}
            value={name}
            autoFocus
            required
            placeholder="eg. FLAT 20% OFF on Kohler Products"
          />
          <button onClick={onSubmit} className="btn-info btn">
            Create
          </button>
        </div>
      </form>
    )
  }

  return (
    <div className="container mt-5 gv-mb">
      <div className="row p-2 bg-white rounded">
        <div className="col-md-8 offset-md-2">
          {loadingMessage()}
          {successMessage()}
          {errorMessage()}
          {ManageOfferAlertForm()}
          {goBack()}
        </div>
      </div>
    </div>
  )
}

export default ManageOfferAlert

import React, { useState, useEffect, useContext } from 'react'
import { signout } from '../firebase/authFunctions'
import { useHistory } from 'react-router-dom'
import firebase from '../../firebase'
import { AuthContext } from '../../AuthProvider'

const UserDashboard = () => {
  const user = useContext(AuthContext)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [order, setOrders] = useState([])

  const fetchUserOrders = async () => {
    var db = firebase.firestore()
    console.log(user.currentUser.displayName)
    const result = await db
      .collection('registered-users')
      .doc(user.currentUser.displayName)
      .collection('orders')
      .get()
    console.log(result)
    setOrders(result.docs.map((doc) => doc.data()))
    setLoading(false)
  }

  useEffect(() => {
    fetchUserOrders()
    setLoading(true)
  }, [])

  return (
    <div className="container gv-mb">
      <div className="container mt-2" style={{ textAlign: 'center' }}>
        <div className="lead" style={{ fontSize: '30px' }}>
          Your Orders
        </div>
      </div>
      {loading ? (
        <center style={{ textAlign: 'center', width: '100%' }}>
          <div className="spinner-border text-dark" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </center>
      ) : order.length === 0 ? (
        <center style={{ textAlign: 'center', width: '100%' }}>
          <p>You have no orders. Checkout the collections to order.</p>
        </center>
      ) : (
        order.map((or) => (
          <div class="card shadow-sm">
            <div class="card-body">
              <div>
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <img
                      src={or.photoURL}
                      alt="Card image cap"
                      height="110px"
                      width="110px"
                    />
                  </div>
                  <div
                    className="col-lg-6 col-sm-12"
                    style={{ fontSize: '20px' }}
                  >
                    <b> Product Name:</b> {or.itemName} <br />
                    <b> Quantity: </b>
                    {or.quantity} <br />
                    <b>Qoute:</b>
                    Rs. {or.qoute}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default UserDashboard

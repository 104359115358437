import firebase from '../../firebase'

// export const signUp = async (userInfo) => {
//   const { email, password } = userInfo
//   console.log(email, password)
//   try {
//     firebase
//       .auth()
//       .createUserWithEmailAndPassword(email, password)
//       .then(({ user }) => {
//         console.log(user.uid)
//         insertUserInDB(user.uid, userInfo)
//       })
//   } catch (error) {
//     alert(error)
//   }
// }

// //insert user in DB
// const insertUserInDB = (uid, userInfo) => {
//   const { firstName, lastName, email, phoneNumber, role } = userInfo

//   const db = firebase.firestore()
//   db.collection('users')
//     .doc(uid)
//     .set({
//       firstName: firstName,
//       lastName: lastName,
//       email: email,
//       phoneNumber: phoneNumber,
//       role: role,
//     })
//     .then((doc) => console.log(doc))
// }

// //sign in user
export const signIn = async (userInfo) => {
  const { email, password } = userInfo
  try {
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        return user
      })
  } catch (error) {
    return error
  }
}

export const signout = async () => {
  try {
    await firebase
      .auth()
      .signOut()
      .then((response) => {
        console.log('sign out done')
        localStorage.removeItem('admin')
        return true
      })
  } catch (error) {
    return error
  }
}

// export const getDocById = (uid) => {
//   var db = firebase.firestore()
//   var docRef = db.collection('users').doc(uid)
//   docRef
//     .get()
//     .then(function (doc) {
//       if (doc.exists) {
//         console.log('Document data:', doc.data().role)
//         return doc.data().role
//       } else {
//         // doc.data() will be undefined in this case
//         console.log('No such document!')
//       }
//     })
//     .catch(function (error) {
//       console.log('Error getting document:', error)
//     })
// }

import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import BannerCard from "../Cards/BannerCard";
import ShowAllProducts from "./ShowAllProducts";

const AllProducts = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getBrandProducts, setGetBrandProducts] = useState(
    localStorage.getItem("all_products_kaytee_view_current_brand")
      ? localStorage.getItem("all_products_kaytee_view_current_brand")
      : "Grohe"
  );
  const [selectedIndex, setSelectedIndex] = useState(
    localStorage.getItem("all_products_kaytee_view_current_index")
      ? localStorage.getItem("all_products_kaytee_view_current_index")
      : "Grohe"
  );
  console.log(getBrandProducts);
  const preload = async () => {
    var db = firebase.firestore();
    const citiesRef = await db.collection("brands").orderBy("sequence").get();
    setBrands(citiesRef.docs.map((doc) => doc.data()));
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    preload();
  }, []);

  return (
    <div>
      <div className="gv-mb">
        <BannerCard bannerName={"All Products"} />
        {loading ? (
          <center
            style={{ textAlign: "center", width: "100%", marginTop: "1rem" }}
          >
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        ) : brands.length === 0 ? (
          <center
            style={{ textAlign: "center", width: "100%", marginTop: "1rem" }}
          >
            <h3 className="font-italic">No Products</h3>
          </center>
        ) : (
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            {brands.map((brand) => (
              <button
                style={{
                  background: selectedIndex === brand.name ? "#f8655a" : "",
                }}
                onClick={() => {
                  setSelectedIndex(brand.name);
                  setGetBrandProducts(brand.name);
                  localStorage.setItem(
                    "all_products_kaytee_view_current_brand",
                    brand.name
                  );
                  localStorage.setItem(
                    "all_products_kaytee_view_current_index",
                    brand.name
                  );
                }}
                className={
                  selectedIndex === brand.name
                    ? "custom-button"
                    : "custom-button"
                }
              >
                <strong>{brand.name}</strong>
              </button>
            ))}
          </div>
        )}
        <ShowAllProducts
          brandName={getBrandProducts ? getBrandProducts : brands[0]}
        />
      </div>
    </div>
  );
};

export default AllProducts;

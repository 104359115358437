import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDx1QReui_BKvuYw45dPKJP3moKHuLJLp0",
  authDomain: "kaytee-international-38030.firebaseapp.com",
  databaseURL: "https://kaytee-international-38030.firebaseio.com",
  projectId: "kaytee-international-38030",
  storageBucket: "kaytee-international-38030.appspot.com",
  messagingSenderId: "825727268233",
  appId: "1:825727268233:web:cd8ab6cea1e4cb22ab5d58",
  measurementId: "G-EHSJW2Y9B0",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.performance();

const storage = firebase.storage();

export { storage, firebase as default };

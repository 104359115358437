import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../firebase'
import axios from 'axios'
import { API } from '../../backend'

const UpdateProduct = (props) => {
  console.log(props.location.state.productID)
  var db = firebase.firestore()
  let arr = []
  let formData = new FormData()
  const [response, setResponse] = useState()
  const [product, setProduct] = useState({
    name: [],
    description: '',
    photoURL: '',
    brand: '',
    subcategory: '',
    originalName: '',
    sequence: '',
    product_id: '',
    documentID: '',
    photo: '',
  })

  const [values, setValues] = useState({
    subcategory: [],
    brands: [],
    loading: false,
    getaRedirect: false,
  })

  const {
    name,
    description,
    price,
    photo,
    stock,
    brands,
    br,
    loading,
    error,
    createaProduct,
    getaRedirect,
    subcat,
    productID,
    subcategory,
    success,
  } = values

  const preload = async () => {
    const product = await db
      .collection('products')
      .doc(props.location.state.productID)
      .get()

    const snapshot = await db.collection('brands').get()
    const subcat = await db.collection('sub-categories').get()
    setValues({
      ...values,
      brands: snapshot.docs.map((doc) => doc.data()),
      subcategory: subcat.docs.map((doc) => doc.data()),
    })

    setProduct({
      ...product,
      name: product.data().name.join(' '),
      description: product.data().description,
      photoURL: product.data().photoURL,
      brand: product.data().brand,
      subcategory: product.data().subcategory,
      product_id: product.data().product_id,
      sequence: product.data().sequence,
      documentID: product.id,
    })

    // if (snapshot.empty) {
    //   console.log('No documents.')
    //   return
    // }
  }

  console.log(subcategory)

  useEffect(() => {
    preload()
  }, [])

  const successMessage = () => {
    return success ? (
      <div className="alert alert-success mt-3">
        <h4>{success.data.message}</h4>
      </div>
    ) : (
      <></>
    )
  }

  // const errorMessage = () => {
  //   return error ? (
  //     <div className="alert alert-danger mt-3">
  //       <h4>{error.data.message}</h4>
  //     </div>
  //   ) : (
  //     <></>
  //   )
  // }
  console.log(product)

  var loadFile = function (event) {
    setProduct({ ...product, photo: event.target.files[0] })
    var reader = new FileReader()
    reader.onload = function () {
      var output = document.getElementById('output')
      output.src = reader.result
    }
    reader.readAsDataURL(event.target.files[0])
  }

  const productIDHandler = (event) => {
    setProduct({ ...product, product_id: event.target.value })
  }
  const descriptionHandler = (event) => {
    setProduct({ ...product, description: event.target.value })
  }
  const nameHandler = (event) => {
    setProduct({ ...product, name: event.target.value })
  }
  const SubCategoryHandler = (event) => {
    setProduct({ ...product, subcategory: event.target.value })
  }
  const categoryHandler = (event) => {
    setProduct({ ...product, brand: event.target.value })
  }

  const createloading = () => {
    if (loading) {
      return (
        <div>
          <center style={{ textAlign: 'center', width: '100%' }}>
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        </div>
      )
    }
  }

  const createProductForm = () => (
    <form>
      <span className="text-dark text-weight-bold">Update product</span>
      <div className="form-group">
        <div style={{ textAlign: 'center' }}>
          <img
            src={product.photoURL}
            height="160px"
            width="160px"
            alt=""
            id="output"
          />
        </div>
        <label className="btn btn-block btn-info">
          <input
            onChange={loadFile}
            type="file"
            name="photo"
            accept="image"
            placeholder="choose a file"
          />
        </label>
      </div>
      <div className="form-group">
        <input
          value={product.name}
          onChange={nameHandler}
          name="name"
          className="form-control"
          placeholder="Name"
        />
      </div>
      <div className="form-group">
        <textarea
          value={product.description}
          onChange={descriptionHandler}
          name="description"
          className="form-control"
          placeholder="Description"
        />
      </div>
      <div className="form-group">
        <textarea
          value={product.product_id}
          onChange={productIDHandler}
          name="product-id"
          className="form-control"
          placeholder="Product ID"
        />
      </div>
      <div className="form-group">
        <select
          onChange={categoryHandler}
          className="form-control"
          placeholder="Category"
        >
          <option>{product.brand}</option>
          {brands &&
            brands.map((cate, index) => {
              return (
                <option value={cate.name} key={index}>
                  {cate.name}
                </option>
              )
            })}
        </select>
      </div>
      <div className="form-group">
        <select
          onChange={SubCategoryHandler}
          className="form-control"
          placeholder="Category"
        >
          <option>{product.subcategory}</option>
          {subcategory &&
            subcategory.map((cate, index) => {
              return (
                <option value={cate.name} key={index}>
                  {cate.name}
                </option>
              )
            })}
        </select>
      </div>
      <button
        type="submit"
        onClick={onSubmit}
        className="btn btn-outline-success mb-3"
      >
        Update Product
      </button>
    </form>
  )

  const onSubmit = async (event) => {
    event.preventDefault()

    setValues({ ...values, loading: true })
    console.log(values)
    formData.append('image', product.photoURL)
    formData.append('description', product.description)
    formData.append('subcategory', product.subcategory)
    formData.append('brand', product.brand)
    formData.append('name', product.name)
    formData.append('product_id', product.product_id)
    formData.append('document_ID', product.documentID)
    formData.append('image', product.photo)
    formData.append('sequence', product.sequence)

    await axios({
      method: 'post',
      url: `${API}/update/product`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        setValues({
          ...values,
          photo: '',
          productID: '',
          name: '',
          description: '',
          br: '',
          subcat: '',
          success: response,
          loading: false,
        })
        console.log(response)
      })
      .catch((err) => {
        setValues({ ...values, error: err, loading: false })
        console.log(err)
      })
  }

  return (
    <div className="container">
      <Link to="/admin/dashboard" className="btn btn-md btn-dark mb-3">
        Go to Dashboard
      </Link>
      <div className="row text-white rounded">
        <div className="col-md-8 offset-md-2">
          {successMessage()}
          {/* {errorMessage()} */}
          {product === undefined ? (
            <center>
              <h3>Loading..</h3>
            </center>
          ) : (
            createProductForm()
          )}
          {createloading()}
        </div>
      </div>
    </div>
  )
}

export default UpdateProduct

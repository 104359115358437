import React from "react";
import firebase from "../../firebase";
import { useEffect, useState } from "react";
import ProductCard from "../Cards/ProductCard";

const Search = (props) => {
  var db = firebase.firestore();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  let sub_categories = [
    "Basin Mixer",
    "Showers & Shower System",
    "Conceald Cistern",
    "Kitchen Taps / Faucets",
    "Pillar Tap",
    "Angle Cock & Conc. Stop Cock / Valve",
    "Smart Control Collections",
    "Thermostatic Collections",
    "Actuator Plate",
    "Bath & Shower Mixer",
    "Bath Inlet / Spout / Filler",
    "Bib Tap",
    "Bath Accessories",
    "Other Products",
    "Angle Valve",
    "Bath & Shower Mixer",
    "Wash Basin",
    "Shower",
    "Bath Inlet / Spout/ Filler",
    "Water Closet / Toilet",
    "Bib Tap & Angle Valve",
    "Bath Inlet / Spout/ Filler",
    "Angle Cock & Conc. Stop Cock / Valve",
    "Flush Valve",
    "Showers",
    "Wall Mixer",
    "uPVC Underground Sewerage Pipes",
    "uPVC Column Pipes",
    "Agricultural uPVC Pressure Pipes & Fittings",
    "SWR uPVC Pipes & Fittings",
    "ASTM PVC-U Plumbing Pipes & Fittings",
    "uPVC Solvents & Rubber Lubricant",
    "uPVC Casing Pipes",
    "uPVC Screen Pipes",
    "Ultra Silent Pipes & Fittings",
    "Water Closet / Toilet",
    "Conceald Cistern",
  ];

  const loadResults = async () => {
    const citiesRef = db.collection("products");
    let snapshot;
    if (sub_categories.includes(props.location.data)) {
      snapshot = await citiesRef
        .where("subcategory", "==", props.location.data)
        .get();
    } else {
      snapshot = await citiesRef
        .where("name", "array-contains-any", props.location.data.split(" "))
        .get();
    }
    if (snapshot.empty) {
      console.log("No matching documents.");
      setResults([]);
      setLoading(false);
      return;
    }

    setResults(snapshot.docs.map((doc) => doc.data()));
    setLoading(false);
    // console.log(results)
  };

  useEffect(() => {
    if (props.location.data) {
      setLoading(true);
      loadResults();
    }
  }, [props.location.data]);

  console.log(props.location.data.split(" "));
  return (
    <div className="gv-mb">
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        {props.location.data ? (
          <h5>
            Showing results for <strong>{props.location.data}</strong>
          </h5>
        ) : (
          <h5>Please Type in the Search bar To search</h5>
        )}
      </div>
      <div className="container">
        {loading ? (
          <center style={{ textAlign: "center", width: "100%" }}>
            <div className="spinner-border text-dark mt-4" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        ) : results.length === 0 ? (
          <center style={{ textAlign: "center", width: "100%" }}>
            <h3>No such Product Found!</h3>
          </center>
        ) : (
          <div className="container">
            <div className="row">
              {results.map((result) => (
                <div className="col-lg-3 col-sm-12 col-md-6 mt-3">
                  <ProductCard product={result} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Search;

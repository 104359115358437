import React, { useState, useEffect } from "react";
import firebase from "../../firebase";
import swal from "sweetalert";

const BecomeDealer = () => {
  const [loading, setLoading] = useState(false);
  const [dealer, setDealer] = useState({
    name: "",
    email: "",
    city: "",
    phoneNumber: "",
    productType: "",
    companyName: "",
  });

  const { name, email, city, phoneNumber, companyName, productType } = dealer;
  const [subcategory, setSubCategory] = useState([]);
  var db = firebase.firestore();

  const handleName = (e) => {
    setDealer({ ...dealer, name: e.target.value });
  };

  const handleCompany = (e) => {
    setDealer({ ...dealer, companyName: e.target.value });
  };

  const handlePhone = (e) => {
    setDealer({ ...dealer, phoneNumber: e.target.value });
  };
  const handleEmail = (e) => {
    setDealer({ ...dealer, email: e.target.value });
  };

  const handleProductType = (e) => {
    setDealer({ ...dealer, productType: e.target.value });
  };

  const handleCity = (e) => {
    setDealer({ ...dealer, city: e.target.value });
  };

  const preload = async () => {
    let result = await db.collection("brands").orderBy("sequence").get();
    setSubCategory(result.docs.map((doc) => doc.data()));
  };
  console.log(subcategory);
  useEffect(() => {
    preload();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(dealer);
    await db
      .collection("dealers")
      .doc(name)
      .set({
        name: name,
        email: email,
        companyName: companyName,
        phoneNumber: phoneNumber,
        productType: productType,
        city: city,
      })
      .then(() => {
        setDealer({
          ...dealer,
          name: "",
          companyName: "",
          phoneNumber: "",
          city: "",
          productType: "",
          email: "",
        });
        setLoading(false);
        swal(
          "Your request has been recorded",
          "We will reach you soon",
          "success"
        );
      });
  };

  return (
    <div className="gv-mb container mt-3">
      <div style={{ textAlign: "center" }}>
        <h3 className="font-weight-light">
          Want to Become a Dealer/Sub-Dealer?
        </h3>
        <p>Fill the form to become a dealer/sub-dealer</p>
      </div>
      {loading ? (
        <center style={{ textAlign: "center", width: "100%" }}>
          <div className="spinner-border text-dark mt-4" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </center>
      ) : (
        <></>
      )}
      <div className="card shadow">
        <form className="p-3">
          <div class="form-row">
            <div class="col-md-6 mb-3">
              <label for="validationDefault01">Full name</label>
              <input
                onChange={handleName}
                type="text"
                class="form-control"
                id="validationDefault01"
                placeholder="John Smith"
                required
              />
            </div>

            <div class="col-md-6 mb-3">
              <label for="validationDefaultUsername">Email</label>
              <div class="input-group">
                <div class="input-group-prepend"></div>
                <input
                  onChange={handleEmail}
                  type="email"
                  class="form-control"
                  id="validationDefaultUsername"
                  placeholder="abc@example.com"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 mb-3">
              <label for="validationDefault03">City</label>
              <input
                onChange={handleCity}
                type="text"
                class="form-control"
                id="validationDefault03"
                placeholder="City"
                required
              />
            </div>

            <div class="col-md-6 mb-3">
              <label for="validationDefault05">Phone Number</label>
              <input
                onChange={handlePhone}
                type="text"
                class="form-control"
                id="validationDefault05"
                placeholder="+1xxxxxxxxx"
                required
              />
            </div>

            <div className="col-lg-6 mb-3">
              <label for="validationDefault05">Select Brand</label>
              <div className="form-group">
                <select
                  onChange={handleProductType}
                  // onChange={SubCategoryHandler}
                  className="form-control"
                  placeholder="Category"
                >
                  <option>Select Brand</option>
                  {subcategory &&
                    subcategory.map((cate, index) => {
                      return (
                        <option value={cate.name} key={index}>
                          {cate.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div class="col-md-6 mb-3">
              <label for="validationDefault05">Company Name</label>
              <input
                onChange={handleCompany}
                type="text"
                class="form-control"
                id="validationDefault05"
                placeholder="Company name"
                required
              />
            </div>
          </div>
          <button onClick={handleSubmit} class="btn btn-primary" type="submit">
            Submit
          </button>
        </form>
      </div>
      <div
        style={{ marginTop: "2rem", fontWeight: "bold", textAlign: "center" }}
      >
        <h3>CHECKOUT BRANDS</h3>
      </div>
      <div className="row">
        <div className=" col-lg-3 col-sm-12 col-md-6">
          <a
            href="https://www.grohe.com/en/corporate/homepage.html"
            target="_blank"
          >
            <img
              className="dealer-cards"
              src={`${process.env.PUBLIC_URL}/images/Grohe.jpg`}
              height="150x"
              width="100%"
              alt=""
            />
          </a>
        </div>
        <div className="col-lg-3 col-sm-12 col-md-6">
          <a href="https://www.americanstandard.in/" target="_blank">
            <img
              className="dealer-cards"
              src={`${process.env.PUBLIC_URL}/images/AS.png`}
              height="150x"
              width="100%"
              alt=""
            />
          </a>
        </div>
        <div className=" col-lg-3 col-sm-12 col-md-6 mt-3">
          <a href="https://www.huliot.in/" target="_blank">
            <img
              className="dealer-cards"
              src={`${process.env.PUBLIC_URL}/images/Arteor.png`}
              height="150x"
              width="100%"
              alt=""
            />
          </a>
        </div>
        <div className="  col-lg-3 col-sm-12 col-md-6 mt-3">
          <a href="https://www.huliot.in/" target="_blank">
            <img
              className="dealer-cards"
              src={`${process.env.PUBLIC_URL}/images/Huliot.png`}
              height="150x"
              width="100%"
              alt=""
            />
          </a>
        </div>
        <div className="col-lg-3 col-sm-12 col-md-6">
          <a href="https://www.finolexpipes.com/" target="_blank">
            <img
              className="dealer-cards"
              src={`${process.env.PUBLIC_URL}/images/Finolex.png`}
              height="150x"
              width="100%"
              alt=""
            />
          </a>
        </div>
        <div className="col-lg-4 col-sm-12 col-md-6 mt-3">
          <a href="https://www.huliot.in/" target="_blank">
            <img
              style={{ objectFit: "contain" }}
              className="dealer-cards"
              src={`${process.env.PUBLIC_URL}/images/newthermo.jpg`}
              height="150x"
              width="300px"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BecomeDealer;

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../firebase'
import axios from 'axios'
import { API } from '../../backend'
import swal from 'sweetalert'

const AddSubCategory = () => {
  const [brands, setBrands] = useState([])
  const [SubCatgory, setSubCategory] = useState({
    name: '',
    brand: '',
    photo: '',
    order: null,
  })
  const { name, brand, photo, order } = SubCatgory
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState()
  var db = firebase.firestore()

  useEffect(() => {
    preload()
  }, [])

  const successMessage = () => {
    if (success) {
      return (
        <div className="alert alert-success mt-2">
          Sub Category created Successfully
        </div>
      )
    }
  }

  const preload = async () => {
    const citiesRef = db.collection('brands')
    const snapshot = await citiesRef.get()
    if (snapshot.empty) {
      console.log('No documents.')
      return
    }
    setBrands(snapshot.docs.map((doc) => doc.data()))
  }

  const errorMessage = () => {
    if (error) {
      return (
        <div className="alert alert-danger mt-2">
          Sub Category creation FAILED
        </div>
      )
    }
  }

  const handleBrandChangeOrder = (event) => {
    setError('')
    setSubCategory({ ...SubCatgory, order: event.target.value })
  }

  const handleSubCategoryChange = (event) => {
    setError('')
    setSubCategory({ ...SubCatgory, name: event.target.value })
  }

  const handleBrandChange = (event) => {
    setError('')
    setSubCategory({ ...SubCatgory, brand: event.target.value })
  }

  const goBack = () => {
    return (
      <Link to="/admin/dashboard" className="btn btn-success">
        Go to Dashboard
      </Link>
    )
  }

  const loadingMessage = () => {
    return (
      loading && (
        <div className="container">
          <center style={{ textAlign: 'center', width: '100%' }}>
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        </div>
      )
    )
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setError('')
    setSuccess(false)
    setLoading(true)

    //req
    let formData = new FormData()
    formData.append('image', photo)
    formData.append('brand', brand)
    formData.append('subcategory', name)
    formData.append('order', order)
    await axios({
      method: 'post',
      url: `${API}/create/subcategory`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((resp) => {
        if (resp.status === 200) {
          setLoading(false)
          swal(`${name} has been created successfully!`, '', 'success')
        } else {
          setLoading(false)
          swal(
            'Could not create Sub Category',
            'Please try again later',
            'error',
          )
        }
      })
      .catch((err) => {
        setLoading(false)
        swal('Could not create Sub Category', 'Please try again later', 'error')
      })
  }

  var loadFile = function (event) {
    setSubCategory({ ...SubCatgory, photo: event.target.files[0] })
    var reader = new FileReader()
    reader.onload = function () {
      var output = document.getElementById('output')
      output.src = reader.result
    }
    reader.readAsDataURL(event.target.files[0])
  }

  const AddSubCategoryForm = () => {
    return (
      <form>
        <div className="form-group">
          <div className="form-group">
            <div style={{ textAlign: 'center' }}>
              <h6>
                Please upload an image from the subcategory as a cover image
              </h6>

              <img height="130px" width="130px" src="" alt="" id="output" />
            </div>

            <label className="btn btn-block btn-info">
              <input
                onChange={loadFile}
                type="file"
                name="photo"
                accept="image"
                placeholder="choose a file"
              />
            </label>
          </div>
          <div className="lead">Enter Sub Category Name</div>
          <input
            type="text"
            className="form-control my-3"
            onChange={handleSubCategoryChange}
            value={name}
            autoFocus
            required
            placeholder="eg. Basin Mixer"
          />
          <div className="lead">Enter Sequence</div>
          <input
            type="number"
            className="form-control my-3"
            onChange={handleBrandChangeOrder}
            value={order}
            autoFocus
            required
            placeholder="eg. 1,2,3..."
          />
        </div>
        <div className="form-group">
          <select
            onChange={handleBrandChange}
            className="form-control"
            placeholder="Category"
          >
            <option>Select Brand</option>
            {brands &&
              brands.map((cate, index) => {
                return (
                  <option value={cate._id} key={index}>
                    {cate.name}
                  </option>
                )
              })}
          </select>
          <button onClick={onSubmit} className=" mt-4 btn-info btn">
            Create
          </button>
        </div>
      </form>
    )
  }

  return (
    <div className="container mt-5 gv-mb">
      <div className="row p-2 bg-white rounded">
        <div className="col-md-8 offset-md-2">
          {successMessage()}
          {errorMessage()}
          {AddSubCategoryForm()}
          {loadingMessage()}
          {goBack()}
        </div>
      </div>
    </div>
  )
}

export default AddSubCategory

import React, { useEffect, useState } from 'react'
import firebase from '../../firebase'

const HomeCarousel = () => {
  const [sliderImages, setsliderImages] = useState([])
  const [loading, setloading] = useState(false)

  var db = firebase.firestore()

  const get_slider_images = async () => {
    setloading(true)
    const img = await db.collection('slider').orderBy('sequence').get()
    setsliderImages(img.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    setloading(false)
  }

  useEffect(() => {
    get_slider_images()
  }, [])

  return (
    <div className="mb-5">
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          {loading ? (
            <div
              className="carousel-item"
              style={{ position: 'relative', minHeight: '450px' }}
            >
              <center>
                <div
                  style={{ width: '3rem', height: '3rem', marginTop: '220px' }}
                  class="spinner-border text-dark"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </center>
            </div>
          ) : sliderImages.length === 0 ? (
            <div
              className="carousel-item active"
              style={{ position: 'relative', minHeight: '450px' }}
            >
              <center>
                <h2 className="mt-5">No Slider Images Found</h2>
              </center>
            </div>
          ) : (
            sliderImages.map((slider, index) => (
              <div
                className={`carousel-item ${index === 0 ? 'active' : ''}`}
                style={{ position: 'relative' }}
              >
                <img
                  src={slider.photoURL}
                  className="  carou-img  w-100"
                  height="450px"
                  alt="..."
                  // style={{ objectFit: 'cover' }}
                />
              </div>
            ))
          )}

          {/* <div className="carousel-item" style={{ position: 'relative' }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/Huliot.jpg`}
              className="  carou-img  w-100"
              height="450px"
              alt="..."
            />
          </div>
          <div className="carousel-item" style={{ position: 'relative' }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/finolex-pipes1.png`}
              className="  carou-img w-100"
              height="450px"
              alt="..."
            />
          </div>
          <div
            className="carousel-item active"
            style={{ position: 'relative' }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/Kohler-Banner.jpg`}
              className=" carou-img  w-100"
              height="450px"
              alt="..."
            />
          </div>
          <div className="carousel-item " style={{ position: 'relative' }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/arteor-banner.jpg`}
              className=" carou-img  w-100"
              height="450px"
              alt="..."
            />
          </div> */}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleCaptions"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleCaptions"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  )
}

export default HomeCarousel

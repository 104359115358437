import { Reloader } from "../../AuthProvider";

export const addItemToCart = (item) => {
  let cart = [];
  if (typeof window !== undefined) {
    if (localStorage.getItem("kaytee_cart")) {
      cart = JSON.parse(localStorage.getItem("kaytee_cart"));
      cart.map((i, index) => {
        console.log(i.name.join(" "));
        console.log(item.name.join(" "));
        if (i.name.join(" ") === item.name.join(" ")) {
          cart.splice(index, 1);
        }
      });
    }
    cart.push({
      ...item,
      quantity: 1,
    });
    localStorage.setItem("kaytee_cart", JSON.stringify(cart));
  }
};

export const quantityUpdate = (itemName, quan) => {
  let cart = [];
  console.log(itemName, quan);
  if (typeof window !== undefined) {
    if (localStorage.getItem("kaytee_cart")) {
      cart = JSON.parse(localStorage.getItem("kaytee_cart"));
      cart.map((i, index) => {
        if (i.name.join(" ") === itemName.join(" ")) {
          console.log(i.quantity);
          i.quantity = quan;
          console.log(i.quantity);
        }
      });
    }
  }
  localStorage.setItem("kaytee_cart", JSON.stringify(cart));
};

export const removeItemFromCart = (itemId) => {
  console.log(itemId);
  let cart = [];
  if (typeof window !== undefined) {
    if (localStorage.getItem("kaytee_cart")) {
      cart = JSON.parse(localStorage.getItem("kaytee_cart"));
    }
  }
  cart.map((item, index) => {
    console.log(item.product_id);
    console.log(itemId);
    if (item.product_id === itemId) {
      cart.splice(index, 1);
    }
  });
  localStorage.setItem("kaytee_cart", JSON.stringify(cart));
  console.log("Cart", cart);
  return cart;
};

export const loadCart = () => {
  if (typeof window !== undefined) {
    if (localStorage.getItem("kaytee_cart")) {
      return JSON.parse(localStorage.getItem("kaytee_cart"));
    }
  }
};

export const cartEmpty = (next) => {
  if (typeof window !== undefined) {
    localStorage.removeItem("kaytee_cart");
  }
  next();
};

import React from 'react'
import BannerCard from '../Cards/BannerCard'
import GoogleApiWrapper from './Contact'
import AboutTeam from './AboutTeam'
const About = () => {
  return (
    <div className="gv-mb">
      <BannerCard bannerName={'About Us'} />
      <div className="container mt-5"></div>
      <AboutTeam />

      <div
        className=""
        style={{ textAlign: 'center', marginTop: '10vh', marginBottom: '3rem' }}
      >
        <div className="row bg-secondary text-white  shadow-sm ml-0">
          <div className="col-lg-6 mt-3 p-5 ">
            <img
              src={`${process.env.PUBLIC_URL}/images/philosophy-icon.png`}
              alt=""
            />
            <h2 style={{ letterSpacing: '4px' }}>PHILOSOPHY</h2>
            <h6>
              Using the guiding policy “Think Customers” we strive to provide
              superior service to cater customer needs. Further by aspiring
              after “Think Customers, co-sharing profits & quality in work”, we
              strive for a brilliant future together.
            </h6>
          </div>
          <div className="col-lg-6 mt-3  p-5 ">
            <img
              src={`${process.env.PUBLIC_URL}/images/vision-icon.png`}
              alt=""
            />
            <h2 style={{ letterSpacing: '4px' }}>VISION</h2>
            <h6>
              To create and sustain value by being recognized as a Leader,
              valued by Customers and Vendors, sought after as an Employer and
              respected by Competitors.
            </h6>
          </div>
        </div>
      </div>
      {/* from the desk */}
      {/* <div className="container mt-4">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12">
            <div className="card ml-2 mr-2">
              <img
                src="https://via.placeholder.com/720x960"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">Member name</h5>
                <p className="card-text">Member Description</p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 mt-3">
            <div>
              <h3>From The Desk of Managing Partner</h3>
              <p>
                Dear Valued Customers, Suppliers & Friends Thanks for visiting
                our web site. <br /> I sincerely hope you enjoy browsing and
                learning more about our company and our many fine products as
                well as suppliers. <br /> We are extremely enthusiastic about
                the opportunities and challenges that lie ahead. <br /> Kay Tee
                Group was founded way back in 1992, by Mr. Haresh Talreja and
                Myself. <br />
                Kay Tee International is a unique company acting as a
                manufacturers’-direct wholesale distribution company in Mumbai,
                while also owning and representing manufacturers with different
                brands throughout the State. <br /> Our goal at Kay Tee
                International is to bring to you the highest quality products at
                a true price value. As we grow we intend to expand our portfolio
                of building materials so we can offer our additional products in
                various categories. <br /> Our overall strategy at Kay Tee
                International is to bring to you good and exciting brands that
                complement our current portfolio of brands and add value to the
                customers. <br /> We are enthusiastic and excited about the
                possibilities and look forward to a great future. <br /> My
                Partner and I look forward to exceeding your expectations while
                enhancing your overall buying experience. <br /> <br /> Thanks
                for stopping by,
              </p>
              <p>
                <strong>Sunill D. Talreja</strong> <br /> CEO / Partner <br />{' '}
                Kay Tee Group
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* core values */}
      <div className="container mt-3" style={{ textAlign: 'center' }}>
        <div className="row">
          <div className="col-lg-6">
            <img
              src={`${process.env.PUBLIC_URL}/images/innovative-icon.png`}
              alt=""
            />
            <h4 className="mt-2">PASSIONATE AND INNOVATIVE</h4>
            <h6>Passion to excel and innovative in finding solution</h6>
          </div>
          <div className="col-lg-6">
            <img
              src={`${process.env.PUBLIC_URL}/images/accountability-icon.png`}
              alt=""
            />
            <h4 className="mt-2">ACCOUNTABILITY</h4>
            <h6>
              Responsible for action. Focus on speed, Mental, Physical and
              Emotional.
            </h6>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-12">
            <img
              src={`${process.env.PUBLIC_URL}/images/working-icon.png`}
              alt=""
            />
            <h4 className="mt-2">INTEGRITY, ETHICAL AND TRANSPARENT WORKING</h4>
            <h6>
              We will treat everyone as we would like to be treated, Respect
              Diversity.
            </h6>
          </div>
        </div>
        <div className="row mt-5 mb-3">
          <div className="col-lg-6">
            <img
              src={`${process.env.PUBLIC_URL}/images/communication-icon.png`}
              alt=""
            />
            <h4 className="mt-2">COMMUNICATION</h4>
            <h6>Encourage open communication to share opinions and views.</h6>
          </div>
          <div className="col-lg-6">
            <img src={`${process.env.PUBLIC_URL}/images/team.png`} alt="" />
            <h4 className="mt-2">TEAM WORK</h4>
            <h6>Passion to excel and innovative in finding solution</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About

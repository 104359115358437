import React, { useState, useEffect } from 'react'
import firebase from 'firebase'

const ManageDealer = () => {
  const [loading, setLoading] = useState(false)
  const [deals, setDeals] = useState([])
  var db = firebase.firestore()

  const preload = async () => {
    let result = await db.collection('dealers').get()
    setDeals(result.docs.map((doc) => doc.data()))
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    preload()
  }, [])

  return (
    <div className="gv-mb">
      <div style={{ textAlign: 'center' }}>
        <h4>Dealer Requests</h4>
      </div>
      {loading ? (
        <center style={{ textAlign: 'center', width: '100%' }}>
          <div className="spinner-border text-dark mt-4" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </center>
      ) : deals.length === 0 ? (
        <center style={{ textAlign: 'center', width: '100%' }}>
          <h4>No deal Requests</h4>
        </center>
      ) : (
        <div className="container" style={{ overflow: 'auto' }}>
          <table class="table">
            <thead class="bg-danger text-white">
              <tr>
                <th scope="col">Full Name</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Company</th>
                <th scope="col">City</th>
                <th scope="col">Product Type</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>
              {deals.map((d) => (
                <tr>
                  <td>{d.name}</td>
                  <td>{d.phoneNumber}</td>
                  <td>{d.companyName}</td>
                  <td>{d.city}</td>
                  <td>{d.productType}</td>
                  <td>{d.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default ManageDealer

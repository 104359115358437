import React, { useEffect, useState, useContext } from 'react'
import { loadCart, removeItemFromCart, quantityUpdate } from './CartHelper'
import BannerCard from '../Cards/BannerCard'
import { AuthContext, Reloader } from '../../AuthProvider'
import { useHistory } from 'react-router-dom'
import NumericInput from 'react-numeric-input'
import swal from 'sweetalert'

const Cart = () => {
  const history = useHistory()
  const [products, setProducts] = useState([])
  const [q, setQ] = useState()
  const r = useContext(AuthContext)
  const reloader = useContext(Reloader)
  console.log(r.reload)

  useEffect(() => {
    setProducts(loadCart())
  }, [r.reload])

  const updateCart = (e, productName) => {
    e.preventDefault()
    quantityUpdate(productName, q)
    swal(
      `Quantity of ${productName.join(' ')} has been updated to ${q}`,
      'Feel free to make changes',
      'success',
    )
  }

  return (
    <div>
      <BannerCard bannerName={'Cart'} />
      <div className="container wrap-table gv-mb mt-4">
        {products === undefined || products.length === 0 ? (
          <center>Your cart is empty!</center>
        ) : (
          <>
            <div style={{ textAlign: 'center' }}>
              <h6 className="mobile-nav">Swipe left to view more content</h6>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col m-0 p-0">Product</th>
                  <th scope="col m-0 p-0">Quantity</th>
                  <th scope="col m-0 p-0">Remove</th>
                  <th scope="col m-0 p-0">Remove</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr>
                    <th scope="row">
                      <img
                        height="100px"
                        width="100px"
                        src={product.photoURL}
                        alt=""
                      />
                    </th>
                    <td className="column-1">{product.name.join(' ')}</td>
                    <td className="column-2">
                      <div className="container">
                        <NumericInput
                          min={0}
                          placeholder={product.quantity}
                          onChange={(value) => setQ(value)}
                          mobile
                          className="form-control"
                        />
                      </div>
                    </td>
                    <td>
                      <button
                        onClick={(e) => {
                          updateCart(e, product.name)
                        }}
                        style={{ width: '10rem' }}
                        className="btn btn-sm p-2 info bg-dark text-white"
                      >
                        UPDATE ITEM
                      </button>
                    </td>
                    <td
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        removeItemFromCart(product.product_id)
                        reloader()
                      }}
                      className="column-2"
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 16 16"
                        class="bi bi-x-square-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm9.854 4.854a.5.5 0 0 0-.708-.708L8 7.293 4.854 4.146a.5.5 0 1 0-.708.708L7.293 8l-3.147 3.146a.5.5 0 0 0 .708.708L8 8.707l3.146 3.147a.5.5 0 0 0 .708-.708L8.707 8l3.147-3.146z"
                        />
                      </svg>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              onClick={() => {
                history.push('/checkout')
              }}
              style={{ float: 'right' }}
              className="btn p-2 info bg-dark text-white"
            >
              REQUEST FOR QOUTE
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default Cart

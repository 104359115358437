import React from 'react'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

const ShowCaseCard = (props) => {
  console.log(props.brand.name.replace(/\//g, ','))

  const history = useHistory()
  return (
    <div>
      <div
        className="card hover-on-zoom product-card  mt-4"
        style={{ textAlign: 'center', height: '333px' }}
      >
        <img
          className="card-img-top"
          src={
            props.brand.photoURL
              ? props.brand.photoURL
              : `${process.env.PUBLIC_URL}/images/banner.jpg`
          }
          alt="Card image cap"
        />
        <div class="card-img-overlay product-card-caption">
          <p
            onClick={() => {
              if (localStorage.getItem('subcategoryname')) {
                localStorage.removeItem('subcategoryname')
                localStorage.removeItem('brand')
                localStorage.setItem('subcategoryname', props.brand.name)
                localStorage.setItem('brand', props.brand.brand)
              } else {
                localStorage.setItem('brand', props.brand.brand)
                localStorage.setItem('subcategoryname', props.brand.name)
              }

              history.push({
                pathname: props.subcategory
                  ? `/brands/${props.brand.brand.replace(
                      '/',
                      ',',
                    )}/${props.brand.name.replace(/\//g, ',')}`
                  : `/brands/${props.brand.name.replace(/\//g, ',')}`,
                state: props.brand.name,
              })
            }}
            className="btn btn-secondary shadow"
          >
            <strong>{props.brand.name}</strong>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ShowCaseCard

import React, { useEffect, useState } from "react";
import HomeCarousel from "./HomeCarousel";
import HomeBrands from "./HomeBrands";
import HomeFeatureProducts from "./HomeFeatureProducts";
import HomeTestimonial from "./HomeTestimonial";
import HomeTeam from "./HomeTeam";
import firebase from "../../firebase";

const db = firebase.firestore();

const Home = () => {
  const [values, setValues] = useState({
    brands: [],
  });
  const [featuredProducts, setFeaturedProducts] = useState([]);

  const { brands } = values;

  //fetch brands from firestore
  const fetchBrandsAndCategories = async () => {
    const citiesRef = db.collection("brands");
    const snapshot = await citiesRef.orderBy("sequence").get();
    setValues({
      ...values,
      brands: snapshot.docs.map((doc) => doc.data()),
    });
  };

  //fetch featured products from firestore
  const fetchFeaturedProducts = async () => {
    const subcat = await db.collection("featured-products").get();
    setFeaturedProducts(subcat.docs.map((doc) => doc.data()));
  };

  console.log(brands);

  useEffect(() => {
    fetchBrandsAndCategories();
    fetchFeaturedProducts();
  }, []);

  return (
    <>
      <div style={{ minHeight: "100%" }}>
        <HomeCarousel />
        <HomeBrands brands={brands} />
        <HomeFeatureProducts featuredProducts={featuredProducts} />
        <HomeTeam />
        {/* <HomeTestimonial /> */}
      </div>
    </>
  );
};

export default Home;

import React, { useState } from 'react'
import { signup } from '../helper/authHelper'
import { Link } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const SignUp = (props) => {
  let setStepTwoRegister = props.setStepTwoRegister
  const [match, setMatch] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userInfo, setUserInfo] = useState({
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    error: '',
    success: false,
  })

  const {
    displayName,
    email,
    phoneNumber,
    password,
    confirmPassword,
    error,
    success,
  } = userInfo

  const successMessage = () => (
    <div
      className="alert alert-success"
      style={{ display: success ? '' : 'none' }}
    >
      New Account Created Successfully! Please{' '}
      <Link to="/signin">Login Here</Link>
    </div>
  )

  const errorMessage = () => (
    <div
      className="alert alert-danger"
      style={{ display: error ? '' : 'none' }}
    >
      {error}
    </div>
  )

  const handleChange = (name) => (event) => {
    setUserInfo({ ...userInfo, error: false, [name]: event.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    setUserInfo({ ...userInfo, error: false })
    signup({ displayName, email, password, phoneNumber })
      .then((data) => {
        console.log('Error' + data.error)
        if (data.error) {
          setLoading(false)
          setUserInfo({ ...userInfo, error: data.error, success: false })
        } else {
          setLoading(false)
          if (props.callingFromCheckout) {
            setStepTwoRegister(true)
            setUserInfo({
              ...userInfo,
              displayName: '',
              email: '',
              password: '',
              phoneNumber: '',
              confirmPassword: '',
              success: true,
              error: '',
            })
          } else {
            setUserInfo({
              ...userInfo,
              displayName: '',
              email: '',
              password: '',
              phoneNumber: '',
              confirmPassword: '',
              success: true,
              error: '',
            })
          }
        }
      })
      .catch((err) => console.log(err))
  }

  console.log(userInfo)
  return (
    <div className=" mt-5 container ">
      {errorMessage()}
      {successMessage()}
      <h3>Register</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label for="exampleInputEmail1">First and Last Name</label>
          <input
            value={displayName}
            onChange={handleChange('displayName')}
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="eg. John Smith"
            required
          />
        </div>
        <div className="form-group">
          <label for="exampleInputEmail1">Phone Number</label>
          <PhoneInput
            country={'in'}
            value={phoneNumber}
            onChange={(value) =>
              setUserInfo({ ...userInfo, phoneNumber: '+' + value })
            }
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: true,
            }}
          />
          <small id="emailHelp" className="form-text text-muted">
            You will not notified with qoutation on this number.
          </small>
        </div>
        <div className="form-group">
          <label for="exampleInputEmail1">Email address</label>
          <input
            value={email}
            onChange={handleChange('email')}
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            required
          />
          <small id="emailHelp" className="form-text text-muted">
            We'll send the qoutation on this email address.
          </small>
        </div>
        <div className="form-group">
          <label for="exampleInputPassword1">Password</label>
          <input
            value={password}
            onChange={handleChange('password')}
            type="password"
            className={match ? `form-control is-valid` : `form-control`}
            id="exampleInputPassword1"
            required
          />
          <div className="valid-feedback">Looks good!</div>
        </div>
        {/* <div className="form-group">
          <label for="exampleInputPassword1">Confirm Password</label>
          <input
            value={confirmPassword}
            onChange={handleChange('confirmPassword')}
            type="password"
            className={match ? `form-control is-valid` : `form-control`}
            id="exampleInputPassword1"
          />
          <div className="valid-feedback">Password matched</div>
        </div> */}
        <div className="form-group form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
            required
          />
          <label className="form-check-label" for="exampleCheck1">
            Agree to Terms and Conditions
          </label>
        </div>

        {loading ? (
          <center style={{ textAlign: 'center', width: '100%' }}>
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        ) : (
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        )}
      </form>
    </div>
  )
}

export default SignUp

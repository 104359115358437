import React, { useEffect, useState } from 'react'
import { API } from '../../backend'
import axios from 'axios'
import swal from 'sweetalert'
import firebase from '../../firebase'

const ManageSliderImage = () => {
  var db = firebase.firestore()
  const [loading, setloading] = useState(false)
  const [sliderImages, setsliderImages] = useState([])
  const [reload, setreload] = useState(false)
  const [values, setValues] = useState({
    photo: '',
    sequence: '',
  })

  const get_slider_images = async () => {
    setloading(true)
    const img = await db.collection('slider').get()
    setsliderImages(img.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    setloading(false)
  }

  const delete_slider_image = async (id, originalname) => {
    swal('Deleting slider', {
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: false,
    })
    let formdata = new FormData()
    formdata.append('doc_id', id)
    formdata.append('originalname', originalname)

    await axios({
      method: 'post',
      url: `${API}/delete/slider/image`,
      data: {
        doc_id: id,
        originalname: originalname,
      },
      headers: { 'Content-Type': 'application/json' },
    })
      .then((rep) => {
        if (rep.error) {
          swal('Could not delete image', '', 'error')
        } else {
          swal('Slider Image Deleted Successfully!', '', 'success')
          setreload(!reload)
        }
      })
      .catch((err) => {
        swal('Could not delete image', '', 'error')
      })
  }

  useEffect(() => {
    setTimeout(() => {
      get_slider_images()
    }, 700)
  }, [reload])

  var loadFile = function (event) {
    setValues({ ...values, photo: event.target.files[0] })
    var reader = new FileReader()
    reader.onload = function () {
      var output = document.getElementById('output')
      output.src = reader.result
    }
    reader.readAsDataURL(event.target.files[0])
  }

  const handleSequence = (e) => {
    setValues({ ...values, sequence: e.target.value })
  }

  //   const handlePhoto = (e) => {
  //     setValues({ ...values, photo: e.target.files[0] })
  //   }

  //add image
  const add_slider_image = async (e) => {
    e.preventDefault()
    setloading(true)
    let formdata = new FormData()
    formdata.append('image', values.photo)
    formdata.append('sequence', values.sequence)

    await axios({
      method: 'post',
      url: `${API}/create/slider/image`,
      data: formdata,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (response.error) {
          setloading(false)
          swal('Could not upload image', '', 'error')
        } else {
          setloading(false)
          setValues({ ...values, photo: '', sequence: '' })
          swal('Slider Image Uploaded Successfully!', '', 'success')
          setreload(!reload)
        }
      })
      .catch((err) => {
        swal('Could not upload image', '', 'error')
      })
  }

  return (
    <div className="gv-mb">
      <br />
      <center>
        <h5>Manage Slider Images</h5>
        <br />
        <img
          className="carou-img"
          width="450px"
          alt="Select a slider image to preview"
          id="output"
        />
      </center>
      <br />

      <div className="container mt-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Upload</span>
          </div>

          <div class="custom-file">
            <input
              onChange={loadFile}
              type="file"
              class="custom-file-input"
              addPhotoToGallery
            />
            <label class="custom-file-label" for="inputGroupFile01">
              Choose file
            </label>
          </div>
        </div>
        <h5>Enter Sequence:</h5>
        <input
          onChange={handleSequence}
          placeholder="Enter sequence for the slider image to appear in slider, eg:1,2,3..."
          type="number"
          id="order"
          className="form-control"
        />
        <div className="text-center">
          {loading ? (
            <h6 className="text-primary">Uploading, please wait..</h6>
          ) : (
            <button onClick={add_slider_image} className="btn btn-primary">
              UPLOAD
            </button>
          )}
        </div>
        <br />
        {loading ? (
          <h5>Loading...</h5>
        ) : sliderImages.length === 0 ? (
          <center>
            <h5>No slider images found!</h5>
          </center>
        ) : (
          <div className="row">
            {sliderImages.map((slider) => (
              <>
                <div className="col-lg-8 mt-2 col-md-8 col-sm-12">
                  <img
                    src={slider.photoURL}
                    className="carou-img shadow-sm"
                    width="450px"
                    alt="Select a slider image to preview"
                    id="output"
                  />
                </div>
                <div className="col-lg-4 col-md-4 mt-4 col-sm-12">
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      delete_slider_image(slider.id, slider.originalname)
                    }}
                    className="btn btn-danger"
                  >
                    DELETE
                  </button>
                </div>
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ManageSliderImage

import React from "react";
import { AuthProvider } from "./AuthProvider";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import AdminDashboard from "./components/admin/AdminDashboard";
import UserDashboard from "./components/user/UserDashboard";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import PrivateRoute from "./components/auth/PrivateRoute";
import AdminRoute from "./components/auth/AdminRoute";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Navbar/Footer";
import AddBrand from "./components/admin/AddCategory";
import AddProduct from "./components/admin/AddProduct";
import AddSubCategory from "./components/admin/AddSubCategory";
import BrandPage from "./components/Brand/BrandPage";
import AllProducts from "./components/Brand/AllProducts";
import SubCategoriesPage from "./components/Brand/SubCategoriesPage";
import Cart from "./components/core/Cart";
import Checkout from "./components/core/Checkout";
import ManagerOrder from "./components/admin/ManageOrder";
import ManageBrandsAndCats from "./components/admin/ManageBrandsAndCats";
import ManageFeaturedProducts from "./components/admin/ManageFeaturedProducts";
import ManageOfferAlert from "./components/admin/ManageOfferAlert";
import ManageEachProduct from "./components/admin/ManageEachProduct";
import About from "./components/AboutContact/About";
import Contact from "./components/AboutContact/Contact";
import BecomeDealer from "./components/AboutContact/BecomeDealer";
import Career from "./components/AboutContact/Career";
import OfferVacancies from "./components/admin/OfferVacancies";
import ManageDealer from "./components/admin/ManageDealers";
import Events from "./components/AboutContact/Events";
import Gallery from "./components/AboutContact/Gallery";
import Privacy from "./components/AboutContact/Privacy";
import AddEvents from "./components/admin/AddEvents";
import AddGallery from "./components/admin/AddGallery";
import Search from "./components/core/Search";
import UpdateProduct from "./components/admin/UpdateProduct";
import CAP from "./components/Brand/CateloguesAndPrice";
import UploadCatalogues from "./components/admin/UploadCatalogues";
import Create from "./components/Testing/Create";
import PDFViewers from "./components/admin/PDFViewer";
import NewCheckout from "./components/core/NewCheckout";
import ProductDetail from "./components/Brand/ProductDetail";
import ManageSliderImage from "./components/admin/ManageSliderImage";

function App() {
  return (
    <div>
      <a
        className="whatsapp-button"
        href="https://wa.me/919769946320?text=Hey%20I%20want%20to%20inquire%20about%20a%20product "
      >
        <img
          height="75px"
          width="75px"
          src={`${process.env.PUBLIC_URL}/images/whatsapp.png`}
          alt=""
        />
      </a>
      <AuthProvider>
        <Navbar />
        {/* <Toast /> */}
        <Switch>
          <Route path="/admin/orders/new" component={ManagerOrder} />
          <AdminRoute path="/admin/create/brand" component={AddBrand} />
          <AdminRoute path="/admin/create/product" component={AddProduct} />
          <AdminRoute
            path="/admin/create/subcategory"
            component={AddSubCategory}
          />
          <AdminRoute
            path="/admin/manage/product/update"
            component={UpdateProduct}
          />
          <AdminRoute
            exact
            path="/admin/manage/featured/products"
            component={ManageFeaturedProducts}
          />
          <AdminRoute
            exact
            path="/admin/manage/brands/manage/subcategories"
            component={ManageBrandsAndCats}
          />
          <AdminRoute
            exact
            path="/admin/manage/:brand/:subcategory"
            component={ManageEachProduct}
          />
          <AdminRoute
            exact
            path="/admin/manage/offer"
            component={ManageOfferAlert}
          />
          <AdminRoute
            exact
            path="/admin/manage/vacancies"
            component={OfferVacancies}
          />
          <AdminRoute
            exact
            path="/admin/manage/dealers"
            component={ManageDealer}
          />
          <AdminRoute
            exact
            path="/admin/upload/catalogue"
            component={UploadCatalogues}
          />
          <AdminRoute
            exact
            path="/admin/manage/gallery"
            component={AddGallery}
          />
          <AdminRoute
            exact
            path="/admin/view/pdf_viewers"
            component={PDFViewers}
          />
          <AdminRoute
            exact
            path="/admin/manage/slider"
            component={ManageSliderImage}
          />
          <AdminRoute exact path="/admin/manage/events" component={AddEvents} />
          <AdminRoute path="/admin" component={AdminDashboard} />
          <PrivateRoute path="/user" component={UserDashboard} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/brands/:id" component={BrandPage} />
          <Route exact path="/brands/:id/:id" component={SubCategoriesPage} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/" component={Home} />
          <Route exact path="/all" component={AllProducts} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/checkout" component={NewCheckout} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/catalogue" component={CAP} />
          <Route exact path="/become-a-dealer" component={BecomeDealer} />
          <Route exact path="/career" component={Career} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/search" component={Search} />
          <Route exact path="/file" component={Create} />
          <Route exact path="/product_detail/:id" component={ProductDetail} />
          <Route exact path="/privacy_policy" component={Privacy} />
        </Switch>
        <Footer />
      </AuthProvider>
    </div>
  );
}

export default App;

import React from "react";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();

  return (
    <div className="footer" style={{ zIndex: "4", marginTop: "1rem" }}>
      <div className="p-1">
        <div className="row mt-2">
          <div className="col-lg-3 mt-4">
            <p className="name">THE COMPANY</p>
            <div className="footer-list">
              <ul>
                <li className="mt-1">Home</li>
                <li className="mt-1">All Products</li>
                <li className="mt-1">Categories and Price Lists</li>
                <li className="mt-1">Become a Dealer</li>
                <li className="mt-1">Career</li>
                <li className="mt-1">About Us</li>
                <li className="mt-1">Contact Us</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 mt-4">
            <p className="name">CATEGORIES</p>
            <div className="footer-list">
              <ul>
                <li className="mt-1">Other Products</li>
                <li className="mt-1">Angle Valve</li>
                <li className="mt-1">Pillar Tap </li>
                <li className="mt-1">Showers </li>
                <li className="mt-1">Wall Mixers </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 mt-4">
            <p className="name">BRANDS</p>
            <div className="footer-list">
              <ul>
                <li className="mt-1">Grohe</li>
                <li className="mt-1">American Standard</li>
                <li className="mt-1">Arteor</li>
                <li className="mt-1">Huliot</li>
                <li className="mt-1">Finolex</li>
                <li className="mt-1">Thermodrain/HP</li>
                <li className="mt-1">Allied Products</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 mt-4">
            <p className="name">USEFUL LINKS</p>
            <div className="footer-list">
              <ul>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/privacy_policy");
                  }}
                >
                  Privacy Policy
                </li>
                <li>Terms and Conditions</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 mt-4">
            <p className="name">FOLLOW US</p>
            <a href="#" class="fa fa-facebook"></a>
            <a href="#" class="fa fa-twitter"></a>
            <a href="#" class="fa fa-instagram"></a>
            <a href="#" class="fa fa-whatsapp"></a>
            <br />
            &copy; {new Date().getFullYear()} Copyrights. All rights reserved.
            <br /> Developed by Ubaid Kolad.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

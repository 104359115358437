import React, { useEffect, useState, useContext } from 'react'
import firebase from 'firebase'
import ProductCard from '../Cards/ProductCard'
import { AuthContext } from '../../AuthProvider'

const ManageEachProduct = ({ match }, props) => {
  //  console.log(props.location.state.subCategoryName)
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  var db = firebase.firestore()
  const seeReload = useContext(AuthContext)
  console.log(match.params.brand.replace(',', '/'))
  console.log(localStorage.getItem('sub_category_display'))

  const getProducts = async () => {
    const result = await db
      .collection('products')
      .where('brand', '==', match.params.brand.replace(',', '/'))
      .where('subcategory', '==', localStorage.getItem('sub_category_display'))
      .get()
    setProducts(result.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    setLoading(false)
  }
  console.log(products)
  useEffect(() => {
    setLoading(true)
    getProducts()
  }, [seeReload.reload])

  return (
    <div className="gv-mb mt-3">
      <div style={{ textAlign: 'center' }}>
        <h6>
          Click on{' '}
          <span>
            {' '}
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 16 16"
              class="bi bi-trash"
              fill="red"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              <path
                fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
          </span>
          to delete products.
        </h6>
      </div>
      {loading ? (
        <center style={{ textAlign: 'center', width: '100%' }}>
          <div className="spinner-border text-dark" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </center>
      ) : products.length === 0 ? (
        <center style={{ textAlign: 'center', width: '100%' }}>
          <h3>No products.</h3>
        </center>
      ) : (
        <div className="container gv,">
          <div className="row">
            {products.map((product) => (
              <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                <ProductCard deleteIcon={true} product={product} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default ManageEachProduct

import React, { useEffect, useState } from 'react'
import firebase from './firebase'
export const AuthContext = React.createContext()
export const Reloader = React.createContext()

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [pending, setPending] = useState(true)
  const [isAdmin, setIsAdmin] = useState()
  const [reload, setReload] = useState(false)

  const toggleReload = () => {
    setReload((prevReload) => !prevReload)
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      setPending(false)
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims.admin) {
              // Show admin UI.
              setIsAdmin(true)
              localStorage.setItem('admin', true)
            } else {
              // Show regular user UI.
              setIsAdmin(false)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })
  }, [])

  if (pending) {
    return (
      <center style={{ paddingTop: '14rem' }}>
        <div className="container">
          <img
            height="200px"
            width="200px"
            src={`${process.env.PUBLIC_URL}/images/loading.png`}
            alt=""
          />
          <p id="loading">Loading...</p>
        </div>
      </center>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isAdmin,
        reload,
      }}
    >
      <Reloader.Provider value={toggleReload}>{children}</Reloader.Provider>
    </AuthContext.Provider>
  )
}

import React, { useState } from "react";
import BannerCard from "../Cards/BannerCard";
import axios from "axios";
import { API } from "../../backend";
import swal from "sweetalert";

const Contact = () => {
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    city: "",
    phone_number: "",
    company_name: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleName = (e) => {
    setUserInfo({ ...userInfo, name: e.target.value });
  };

  const handleEmail = (e) => {
    setUserInfo({ ...userInfo, email: e.target.value });
  };
  const handleCity = (e) => {
    setUserInfo({ ...userInfo, city: e.target.value });
  };
  const handlePhoneNumber = (e) => {
    setUserInfo({ ...userInfo, phone_number: e.target.value });
  };
  const handleCompanyName = (e) => {
    setUserInfo({ ...userInfo, company_name: e.target.value });
  };
  const handleMessage = (e) => {
    setUserInfo({ ...userInfo, message: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios({
      method: "post",
      url: `${API}/send_email`,
      data: userInfo,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((data) => {
      if (data.error) {
        swal(
          "Could not record your inquiry",
          "Please try again later",
          "error"
        );
        setLoading(false);
      } else {
        swal(
          "Thanks for submitting an inquiry",
          "We will reach you soon",
          "success"
        );
        setUserInfo({
          name: "",
          email: "",
          city: "",
          phone_number: "",
          company_name: "",
          message: "",
        });
        setLoading(false);
      }
    });
  };

  return (
    <div className="gv-mb">
      <BannerCard bannerName={"Contact Us"} />
      <div className="container mt-4">
        <div class="card shadow">
          <div class="card-body p-0">
            <div className="row m-0">
              <div className="col-lg-6">
                <div className="container">
                  <br />
                  <form onSubmit={onSubmit}>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="validationDefault01">First name</label>
                        <input
                          onChange={handleName}
                          type="text"
                          class="form-control"
                          id="validationDefault01"
                          placeholder="First name"
                          required
                        />
                      </div>

                      <div class="col-md-6 mb-3">
                        <label for="validationDefaultUsername">Email</label>
                        <div class="input-group">
                          <div class="input-group-prepend"></div>
                          <input
                            onChange={handleEmail}
                            type="email"
                            class="form-control"
                            id="validationDefaultUsername"
                            placeholder="abc@example.com"
                            aria-describedby="inputGroupPrepend2"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="validationDefault03">City</label>
                        <input
                          type="text"
                          onChange={handleCity}
                          class="form-control"
                          id="validationDefault03"
                          placeholder="City"
                          required
                        />
                      </div>

                      <div class="col-md-6 mb-3">
                        <label for="validationDefault05">Phone Number</label>
                        <input
                          type="text"
                          onChange={handlePhoneNumber}
                          class="form-control"
                          id="validationDefault05"
                          placeholder="+1xxxxxxxxx"
                          required
                        />
                      </div>

                      <div class="col-md-12 mb-3">
                        <label for="validationDefault05">Company Name</label>
                        <input
                          type="text"
                          class="form-control"
                          onChange={handleCompanyName}
                          id="validationDefault05"
                          placeholder="Company name"
                          required
                        />
                      </div>
                      <div class="col-md-12 col-lg-12  mb-3">
                        <label for="validationDefault05">
                          Enter your message
                        </label>
                        <textarea
                          onChange={handleMessage}
                          placeholder="Enter your message"
                          class="form-control"
                          rows="4"
                          aria-label="With textarea"
                        ></textarea>
                      </div>
                    </div>

                    {loading ? (
                      <button disabled class="btn btn-primary" type="submit">
                        Please wait...
                      </button>
                    ) : (
                      <button class="btn btn-primary" type="submit">
                        Submit
                      </button>
                    )}
                  </form>
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{ backgroundColor: "#4a4c55", color: "white" }}
              >
                <div className="container">
                  <br />
                  <h3 className="text-white">Contact information</h3>
                  <p>
                    <span className="mr-4">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-building"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                        />
                        <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                      </svg>
                    </span>
                    KAY TEE INTERNATIONAL
                  </p>
                  <div className="row ml-0">
                    <div className="col-1 col-md-1 p-0 m-0">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-geo-alt"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                        />
                      </svg>
                    </div>
                    <div className="col-11 col-md-11 p-0 m-0">
                      {" "}
                      <span>
                        {" "}
                        A<span className="phone-no">103</span>, Classic Corner,{" "}
                        <br />
                        <span className="phone-no">133</span>, Hill Road, <br />{" "}
                        Bandra West, <br /> Mumbai{" "}
                        <span className="phone-no">400 050,</span> <br /> India.
                      </span>
                    </div>
                  </div>
                  <p></p>
                  <p>
                    <span className="mr-4">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-telephone-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"
                        />
                      </svg>
                    </span>
                    <span className="phone-no">02226410733 / 02226410734</span>
                  </p>
                  <p>
                    <span className="mr-4">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-envelope-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
                        />
                      </svg>
                    </span>
                    sales@kayteegroup.in
                  </p>
                  <div className="row ml-0">
                    <div className=" m-0 p-0 col-1 col-md-1">
                      <p>
                        <span>
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-clock-fill"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
                            />
                          </svg>
                        </span>
                      </p>
                    </div>
                    <div className="col-11 p-0 mt-0 ml-0 mr-0 mb-2 col-md-11">
                      Our opening hours: <br /> MON – SAT DAY:{" "}
                      <span className="phone-no">10.00 – 18.00</span> HRS.{" "}
                      <br /> SUNDAY CLOSED
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: "1rem",
          marginBottom: "-15rem",
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.2018460307095!2d72.82597681440537!3d19.054861057589395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c93f81334513%3A0xda896b18aab097a8!2sKay%20Tee%20International!5e0!3m2!1sen!2sin!4v1596389907112!5m2!1sen!2sin"
          width="100%"
          height="450"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import BannerCard from "../Cards/BannerCard";
import firebase from "../../firebase";
import { useState } from "react";
import { useEffect } from "react";
import swal from "sweetalert";
const CAP = () => {
  var db = firebase.firestore();
  const [catalogues, setCatalogues] = useState([]);
  const [loading, setLoading] = useState(false);
  let c;
  const [currentPDF, setcurrentPDF] = useState();
  const [viewerDetails, setviewerDetails] = useState({
    fullName: "",
    companyName: "",
    phoneNumber: "",
    email: "",
    pdf_viewed: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFullName = (e) => {
    setviewerDetails({ ...viewerDetails, fullName: e.target.value });
  };

  const handleEmail = (e) => {
    setviewerDetails({ ...viewerDetails, email: e.target.value });
  };

  const handlePhoneNumber = (e) => {
    setviewerDetails({ ...viewerDetails, phoneNumber: e.target.value });
  };

  const handleCompanyName = (e) => {
    setviewerDetails({ ...viewerDetails, companyName: e.target.value });
  };

  const preload = async () => {
    const result = await db.collection("catalogues").orderBy("sequence").get();
    setCatalogues(result.docs.map((doc) => doc.data()));
    setLoading(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    setviewerDetails({ ...viewerDetails, pdf_viewed: currentPDF.name });
    db.collection("pdf_viewers")
      .doc()
      .set(viewerDetails)
      .then(() => {
        setLoading(false);
        setviewerDetails({
          fullName: "",
          companyName: "",
          email: "",
          phoneNumber: "",
          pdf_viewed: "",
        });

        window.open(currentPDF.download_url);
        window.location.reload();
      });
  };

  console.log(catalogues);

  useEffect(() => {
    setLoading(true);
    preload();
  }, []);

  return (
    <div>
      <BannerCard bannerName={"Catelogues and Price Lists"} />
      <div className="container gv-mb mt-4">
        {loading ? (
          <center style={{ textAlign: "center", width: "100%" }}>
            <div className="spinner-border text-dark mt-4" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        ) : catalogues.length == 0 ? (
          <center style={{ textAlign: "center", width: "100%" }}>
            <div>
              <h3>No catalogues</h3>
            </div>
          </center>
        ) : (
          catalogues.map((catalogue) => (
            <div
              data-toggle="modal"
              data-target="#exampleModal"
              onClick={(e) => {
                e.preventDefault();
                c = catalogue.name;
                setcurrentPDF(catalogue);
                document.getElementById("navbar").style.zIndex = 0;
              }}
              className="card shadow-sm catal-card"
            >
              <div className="card-body">
                <div class="d-flex bd-highlight">
                  <div class="p-2 flex-grow-1 bd-highlight">
                    <h3>{catalogue.name}</h3>
                  </div>
                  <div class="p-2 bd-highlight">
                    <strong>|</strong>
                  </div>
                  <div class="p-2 bd-highlight">
                    <a href={catalogue.download_url} target="blank">
                      <img
                        height="40px"
                        src={`${process.env.PUBLIC_URL}/images/pdf_download.jpg`}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Enter the details to view Catalogue
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form onSubmit={onSubmit}>
                <div class="form-group">
                  <label for="exampleFormControlInput1">Full Name</label>
                  <input
                    type="text"
                    onChange={handleFullName}
                    class="form-control"
                    required
                    id="exampleFormControlInput1"
                    placeholder="John Doe"
                  />
                  <label for="exampleFormControlInput1">Company Name</label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={handleCompanyName}
                    required
                    id="exampleFormControlInput1"
                    placeholder="eg. Kay Tee International"
                  />
                  <label for="exampleFormControlInput1">Phone Number</label>
                  <input
                    type="number"
                    class="form-control"
                    required
                    minLength="10"
                    onChange={handlePhoneNumber}
                    id="exampleFormControlInput1"
                    placeholder="Phone number with country code"
                  />
                  <label for="exampleFormControlInput1">Email address</label>
                  <input
                    type="email"
                    onChange={handleEmail}
                    class="form-control"
                    required
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                  />
                </div>
                {/* <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button> */}
                <center>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <button type="submit" class="btn btn-primary">
                      View Catalogue
                    </button>
                  )}
                </center>
              </form>
            </div>
            {/* <div class="modal-footer">
             
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CAP;

import React from 'react'
import firebase from '../../firebase'
import { useEffect } from 'react'
import { useState } from 'react'
import swal from 'sweetalert'

const AddEvents = () => {
  const db = firebase.firestore()
  const [event, setEvent] = useState({
    name: '',
    date: '',
  })

  const { name, date } = event

  const addEvent = async (e) => {
    e.preventDefault()
    await db
      .collection('events')
      .doc()
      .set({
        name: name,
        date: date,
      })
      .then(() => {
        swal('Event added', '', 'success')
      })
  }

  const nameHandler = (e) => {
    setEvent({ ...event, name: e.target.value })
  }

  const dateHandler = (e) => {
    setEvent({ ...event, date: e.target.value })
  }

  return (
    <div className="gv-mb">
      <div className="container mt-4">
        <div style={{ textAlign: 'center' }}>
          <h3>Add Events</h3>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Enter Event Name</label>
          <input
            onChange={nameHandler}
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter Event Name"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Enter Event Date</label>
          <input
            onChange={dateHandler}
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter Event Date"
          />
        </div>
        <button onClick={addEvent} className="btn btn-dark">
          ADD
        </button>
      </div>
    </div>
  )
}
export default AddEvents

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../firebase'
import axios from 'axios'
import { API } from '../../backend'

const AddProduct = () => {
  var db = firebase.firestore()
  let arr = []
  let formData = new FormData()

  const [response, setResponse] = useState()
  const [subCategoryLoading, setsubCategoryLoading] = useState(false)

  const [values, setValues] = useState({
    name: [],
    subcat: '',
    subcategory: [],
    description: 'Chrome',
    productID: '',
    price: '',
    stock: '',
    size: '',
    photo: '',
    brands: [],
    br: '',
    success: '',
    loading: false,
    error: '',
    createaProduct: '',
    getaRedirect: false,
  })

  const {
    name,
    description,
    price,
    size,
    photo,
    stock,
    brands,
    br,
    loading,
    error,
    createaProduct,
    getaRedirect,
    subcat,
    productID,
    subcategory,
    success,
  } = values

  const preload = async () => {
    const citiesRef = db.collection('brands')
    const snapshot = await citiesRef.get()
    const subc = await db.collection('sub-categories').get()
    setValues({
      ...values,
      brands: snapshot.docs.map((doc) => doc.data()),
      subcategory: subc.docs.map((doc) => doc.data()),
    })

    // if (snapshot.empty) {
    //   console.log('No documents.')
    //   return
    // }
  }
  // console.log(subcategory)
  console.log(values)
  useEffect(() => {
    preload()
  }, [])

  const successMessage = () => {
    return success ? (
      <div className="alert alert-success mt-3">
        <h4>{success.data.message}</h4>
      </div>
    ) : (
      <></>
    )
  }

  // const errorMessage = () => {
  //   return error ? (
  //     <div className="alert alert-danger mt-3">
  //       <h4>{error.data.message}</h4>
  //     </div>
  //   ) : (
  //     <></>
  //   )
  // }

  var loadFile = function (event) {
    setValues({ ...values, photo: event.target.files[0] })
    var reader = new FileReader()
    reader.onload = function () {
      var output = document.getElementById('output')
      output.src = reader.result
    }
    reader.readAsDataURL(event.target.files[0])
  }

  const sizeHandler = (event) => {
    setValues({ ...values, size: event.target.value })
  }

  const productIDHandler = (event) => {
    setValues({ ...values, productID: event.target.value })
  }
  const descriptionHandler = (event) => {
    setValues({ ...values, description: event.target.value })
  }
  const nameHandler = (event) => {
    setValues({ ...values, name: event.target.value })
  }
  const SubCategoryHandler = (event) => {
    setValues({ ...values, br: event.target.value })
  }

  const categoryHandler = async (event) => {
    setsubCategoryLoading(true)
    localStorage.setItem('current_brand_admin_kaytee', event.target.value)
    setValues({ ...values })
    const c = await db
      .collection('sub-categories')
      .where('brand', '==', event.target.value)
      .get()
    setValues({
      ...values,
      subcat: localStorage.getItem('current_brand_admin_kaytee'),
      subcategory: c.docs.map((doc) => doc.data()),
    })
    setsubCategoryLoading(false)
  }

  const createloading = () => {
    if (loading) {
      return (
        <div>
          <center style={{ textAlign: 'center', width: '100%' }}>
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        </div>
      )
    }
  }

  console.log(subcat)

  const createProductForm = () => (
    <form>
      <span className="text-dark text-weight-bold">Create a product</span>
      <div className="form-group">
        <div style={{ textAlign: 'center' }}>
          <img src="" height="160px" width="160px" alt="" id="output" />
        </div>
        <label className="btn btn-block btn-info">
          <input
            onChange={loadFile}
            type="file"
            name="photo"
            accept="image"
            placeholder="choose a file"
          />
        </label>
      </div>
      <div className="form-group">
        <input
          onChange={nameHandler}
          name="name"
          className="form-control"
          placeholder="Name"
        />
      </div>
      <div className="form-group">
        <textarea
          value={description}
          onChange={descriptionHandler}
          name="description"
          className="form-control"
          placeholder="Description"
        />
      </div>
      <div className="form-group">
        <textarea
          value={productID}
          onChange={productIDHandler}
          name="product-id"
          className="form-control"
          placeholder="Product ID (leave empty if tsubcatere's no product SKU)"
        />
      </div>
      <div className="form-group">
        <textarea
          value={size}
          onChange={sizeHandler}
          name="product-id"
          className="form-control"
          placeholder="Size (leave empty if there's no size)"
        />
      </div>
      <div className="form-group">
        <select
          value={localStorage.getItem('current_brand_admin_kaytee')}
          onChange={categoryHandler}
          className="form-control"
          placeholder="Category"
        >
          <option>Select Category</option>
          {brands &&
            brands.map((cate, index) => {
              return (
                <option value={cate.name} key={index}>
                  {cate.name}
                </option>
              )
            })}
        </select>
      </div>
      <div className="form-group">
        <select
          value={br}
          onChange={SubCategoryHandler}
          className="form-control"
          placeholder="Category"
        >
          <option>Select Sub Category</option>
          {subCategoryLoading ? (
            <option disabled>Loading...</option>
          ) : (
            subcategory &&
            subcategory.map((cate, index) => {
              return (
                <option value={cate.name} key={index}>
                  {cate.name}
                </option>
              )
            })
          )}
        </select>
      </div>
      <button
        type="submit"
        onClick={onSubmit}
        className="btn btn-outline-success mb-3"
      >
        Create Product
      </button>
    </form>
  )

  const onSubmit = async (event) => {
    event.preventDefault()
    setValues({ ...values, loading: true })
    formData.append('image', photo)
    formData.append('name', name)
    formData.append('brand', subcat)
    formData.append('description', description)
    formData.append('subcategory', br)
    formData.append('product_id', productID)
    formData.append('size', size)

    await axios({
      method: 'post',
      url: `${API}/create`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        setValues({
          ...values,
          photo: '',
          productID: '',
          name: '',
          description: '',
          br: '',
          subcat: '',
          success: response,
          loading: false,
        })
        console.log(response)
      })
      .catch((err) => {
        setValues({ ...values, error: err, loading: false })
        console.log(err)
      })
  }

  return (
    <div className="container">
      <Link to="/admin/dashboard" className="btn btn-md btn-dark mb-3">
        Go to Dashboard
      </Link>
      <div className="row text-white rounded">
        <div className="col-md-8 offset-md-2">
          {successMessage()}
          {/* {errorMessage()} */}
          {createProductForm()}
          {createloading()}
        </div>
      </div>
    </div>
  )
}

export default AddProduct

import React, { useEffect, useState } from "react";
import BannerCard from "../Cards/BannerCard";
import firebase from "../../firebase";

const Gallery = () => {
  var db = firebase.firestore();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);

  const preload = async () => {
    let result = await db.collection("gallery").get();
    setPhotos(result.docs.map((doc) => doc.data()));
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    preload();
  }, []);

  return (
    <div className="gv-mb">
      <BannerCard bannerName={"Gallery"} />
      <div className="container mt-4">
        {loading ? (
          <center style={{ textAlign: "center", width: "100%" }}>
            <div className="spinner-border text-dark mt-4" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        ) : photos.length === 0 ? (
          <center style={{ textAlign: "center", width: "100%" }}>
            <h3>Gallery is empty!</h3>
          </center>
        ) : (
          <div className="container">
            <div className="row">
              {photos.map((photo) => (
                <div className="col-lg-4 col-md-4 col-sm-12 mt-2">
                  <img
                    height="100%"
                    //width="350px"
                    className="img-fluid gallery-card shadow"
                    src={photo.photoURL}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;

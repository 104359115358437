import React, { Component, useState, useEffect } from 'react'
import Slider from 'react-slick'
import FadeInSection from '../../FadeInSection'

const HomeFeatureProducts = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow:
      props.featuredProducts.length < 3 ? props.featuredProducts.length : 3,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const [loading, setLoading] = useState(false)

  return (
    <div className="container mt-5 mb-5" style={{ zIndex: '2' }}>
      <FadeInSection>
        {props.featuredProducts.length === 0 ? (
          <></>
        ) : (
          <>
            <div className="container mb-5" style={{ textAlign: 'center' }}>
              <h3 className="headings">FEATURED PRODUCTS</h3>
            </div>
            <Slider {...settings}>
              {props.featuredProducts.map((featuredProduct) => (
                <div
                  className="card mt-2 pl-3 pr-3"
                  style={{ width: '18rem', border: 'none' }}
                >
                  <img
                    height="250px"
                    width="250px"
                    className="card-img-top"
                    src={featuredProduct.photoURL}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <div className="card-text">
                      <p>
                        <strong>{featuredProduct.name}</strong>
                      </p>
                      <p className="font-italics">{featuredProduct.brand}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </>
        )}
      </FadeInSection>
    </div>
  )
}

export default HomeFeatureProducts

import React, { useEffect, useState } from 'react'
import ShowCaseCard from '../Cards/ShowCaseCard'
import firebase from 'firebase'
import BannerCard from '../Cards/BannerCard'

const BrandPage = (props) => {
  const [subCategories, setSubCategories] = useState([])
  const [loading, setLoading] = useState(false)
  var db = firebase.firestore()

  const fetchSubCategories = async () => {
    const subcat = await db
      .collection('sub-categories')
      .where('brand', '==', props.location.state.replace(',', '/'))
      .orderBy('order')
      .get()
    setLoading(false)
    setSubCategories(subcat.docs.map((doc) => doc.data()))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setLoading(true)
    fetchSubCategories()
  }, [])
  console.log(subCategories)
  console.log(props.location.state.replace(',', '/'))
  return (
    <>
      <div>
        <BannerCard bannerName={props.location.state.replace(',', '/')} />
      </div>
      <div className="container gv-mb" style={{ textAlign: 'center' }}>
        <div className="row">
          {loading ? (
            <center style={{ textAlign: 'center', width: '100%' }}>
              <div className="spinner-border text-dark mt-4" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </center>
          ) : subCategories.length === 0 ? (
            <center style={{ textAlign: 'center', width: '100%' }}>
              <div className=" text-dark mt-4 display-6" role="status">
                <strong>NO SUBCATEGORIES FOUND!</strong>
              </div>
            </center>
          ) : (
            subCategories.map((category) => {
              return (
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <ShowCaseCard brand={category} subcategory={true} />
                </div>
              )
            })
          )}
        </div>
      </div>
    </>
  )
}

export default BrandPage

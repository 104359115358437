import React from 'react'
import { Link } from 'react-router-dom'
import ShowCaseCard from '../Cards/ShowCaseCard'
import FadeInSection from '../../FadeInSection'

const HomeBrands = (props) => {
  return (
    <div className="container" style={{ zIndex: '1' }}>
      <FadeInSection>
        <div className="container mt-5 mb-3" style={{ textAlign: 'center' }}>
          <h3 className="headings">OUR BRANDS</h3>
        </div>
        <div className="row mt-4">
          {props.brands.length === 0 ? (
            <center style={{ textAlign: 'center', width: '100%' }}>
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </center>
          ) : (
            props.brands.map((brand) => (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <ShowCaseCard brand={brand} />
              </div>
            ))
          )}
        </div>
      </FadeInSection>
    </div>
  )
}

export default HomeBrands

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API } from '../../backend'
import firebase from 'firebase'
import ProductCard from '../Cards/ProductCard'
import swal from 'sweetalert'

const ManageFeaturedProducts = () => {
  let formData = new FormData()
  var db = firebase.firestore()
  const [featuredProducts, setFeaturedProducts] = useState([])
  const [reload, setReload] = useState(false)
  const [l, setL] = useState(false)
  const [values, setValues] = useState({
    name: '',
    photo: '',
    brand: '',
    loading: false,
    success: '',
  })

  const fetchFeaturedProducts = async () => {
    const subcat = await db.collection('featured-products').get()
    setFeaturedProducts(subcat.docs.map((doc) => doc.data()))
    setL(false)
  }

  useEffect(() => {
    setL(true)
    fetchFeaturedProducts()
  }, [reload])

  const photoHandler = (event) => {
    setValues({ ...values, photo: event.target.files[0] })
  }
  const brandHandler = (event) => {
    setValues({ ...values, brand: event.target.value })
  }
  const nameHandler = (event) => {
    setValues({ ...values, name: event.target.value })
  }

  const { name, photo, brand, loading, success } = values

  const deleteFeaturedProduct = async (e, product) => {
    e.preventDefault()
    setValues({ ...values, loading: false })
    await axios({
      method: 'post',
      url: `${API}/delete/featured/product`,
      data: product,
      headers: { Accept: 'application/json' },
    })
      .then((response) => {
        if (response.status === 200) {
          setValues({ ...values, loading: false })
          setReload(!reload)
          swal(
            `${product.name} has been deleted successfully`,
            'Please try to keep atleast 3 products in the featured section',
            'success',
          )
        } else {
          setValues({ ...values, loading: false })
          swal(
            `${product.name} could not deleted`,
            'Please try again later',
            'error',
          )
        }
      })
      .catch((err) => {
        setValues({ ...values, loading: false })
        swal(
          `${product.name} could not deleted`,
          'Please try again later',
          'error',
        )
      })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setValues({ ...values, loading: true })
    console.log(values)
    formData.append('image', photo)
    formData.append('name', name)
    formData.append('brand', brand)
    console.log(formData)
    await axios({
      method: 'post',
      url: `${API}/create/featured/product`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        setValues({
          ...values,
          photo: '',
          name: '',
          brand: '',
          loading: false,
          success: response,
        })
        console.log(response)
      })
      .catch((err) => {
        setValues({ ...values, error: err, loading: false })
        console.log(err)
      })
  }

  const successMessage = () => {
    return success ? (
      <div className="alert alert-success mt-3">
        <h4>{success.data.message}</h4>
      </div>
    ) : (
      <></>
    )
  }

  const createloading = () => {
    if (loading) {
      return (
        <div>
          <center style={{ textAlign: 'center', width: '100%' }}>
            <div className="spinner-border text-dark mt-4" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        </div>
      )
    }
  }

  const createProductForm = () => (
    <form>
      <br />
      <h4 className="text-dark text-weight-bold">Create a product</h4>
      <div className="form-group">
        <label className="btn btn-block btn-info">
          <input
            onChange={photoHandler}
            type="file"
            name="photo"
            accept="image"
            placeholder="choose a file"
          />
        </label>
      </div>
      <div className="form-group">
        <input
          onChange={nameHandler}
          name="name"
          value={name}
          className="form-control"
          placeholder="Name"
        />
      </div>
      <div className="form-group">
        <input
          onChange={brandHandler}
          name="brand"
          value={brand}
          className="form-control"
          placeholder="Enter Brand"
        />
      </div>

      <button
        type="submit"
        onClick={onSubmit}
        className="btn btn-outline-success mb-3"
      >
        Create Product
      </button>
    </form>
  )

  const showFeatureProducts = () => {
    return (
      <div className="container">
        <div style={{ textAlign: 'center' }}>
          <h6>
            Click on{' '}
            <span>
              {' '}
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="bi bi-trash"
                fill="red"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fill-rule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </span>
            to delete Featured products.
          </h6>
        </div>
        <div className="row">
          {l ? (
            <center>loadddd</center>
          ) : featuredProducts.length === 0 ? (
            <center>noprodcucs</center>
          ) : (
            featuredProducts.map((featuredProduct) => (
              <div className="col-lg-3 col-sm-12 col-md-6">
                <div
                  className="card mt-2 pl-3 pr-3"
                  style={{ width: '18rem', border: 'none' }}
                >
                  <img
                    style={{ padding: '7px' }}
                    height="250px"
                    width="250px"
                    className="card-img-top"
                    src={featuredProduct.photoURL}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <div className="card-text">
                      <p>{featuredProduct.name}</p>
                      <p className="font-italics">{featuredProduct.brand}</p>
                      <p>
                        <svg
                          onClick={(e) => {
                            deleteFeaturedProduct(e, featuredProduct)
                          }}
                          style={{ cursor: 'pointer' }}
                          width="2em"
                          height="2em"
                          viewBox="0 0 16 16"
                          class="bi bi-trash"
                          fill="red"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path
                            fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                          />
                        </svg>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    )
  }

  return (
    <div style={{ textAlign: 'center' }} className="gv-mb">
      <h3>Manage Featured Products</h3>
      <br />
      <div className="container">
        {createloading()}
        {successMessage()}
        {createProductForm()}
        {showFeatureProducts()}
      </div>
    </div>
  )
}

export default ManageFeaturedProducts

import React, { useEffect } from "react";
import BannerCard from "../Cards/BannerCard";

const ProductDetail = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(props.location.state.product.id);

  return (
    <div className="gv-mb">
      <BannerCard bannerName={props.location.state.product.name.join(" ")} />
      <br />
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <img
                className="img-fluid"
                src={props.location.state.product.photoURL}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card h-100 ">
              <ul className="mt-5 ml-5">
                <li>
                  <h6>
                    <strong>Name: </strong>
                    {props.location.state.product.name.join(" ")}
                  </h6>
                </li>
                {!props.location.state.product.product_id ? (
                  <></>
                ) : (
                  <li className="mt-1 ">
                    <h6>
                      <strong>SKU: </strong>
                      <span className="phone-no">
                        {props.location.state.product.product_id}
                      </span>
                    </h6>
                  </li>
                )}
                {!props.location.state.product.colour ? (
                  <></>
                ) : (
                  <li className="mt-1 ">
                    <h6>
                      <strong>Colour: </strong>
                      <span className="phone-no">
                        {props.location.state.product.colour}
                      </span>
                    </h6>
                  </li>
                )}
                {!props.location.state.product.length ? (
                  <></>
                ) : (
                  <li className="mt-1 ">
                    <h6>
                      <strong>Length: </strong>
                      <span className="phone-no">
                        {props.location.state.product.length}
                      </span>
                    </h6>
                  </li>
                )}

                <li className="mt-1 ">
                  <h6>
                    <strong>Size: </strong>
                    <span className="phone-no">
                      {!props.location.state.product.size
                        ? "NA"
                        : props.location.state.product.size}
                    </span>
                  </h6>
                </li>
                <li className="mt-1 ">
                  <h6>
                    <strong>Load Bearing: </strong>
                    <span className="phone-no">
                      {!props.location.state.product.load_bearing
                        ? "NA"
                        : props.location.state.product.load_bearing}
                    </span>
                  </h6>
                </li>
                <li className="mt-1 ">
                  <h6>
                    <strong>Finish: </strong>
                    <span className="phone-no">
                      {!props.location.state.product.description
                        ? "NA"
                        : props.location.state.product.description}
                    </span>
                  </h6>
                </li>
                <li className="mt-1 ">
                  <h6>
                    <strong>Brand: </strong>
                    <span className="phone-no">
                      {props.location.state.product.brand}
                    </span>
                  </h6>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductDetail;

import React, { useState } from 'react'
import firebase from '../../firebase'
import axios from 'axios'
import { API } from '../../backend'
import swal from 'sweetalert'

const UploadCatalogues = () => {
  const storageRef = firebase.storage()
  var formData = new FormData()
  var db = firebase.firestore()
  const [loading, setLoading] = useState(false)
  const [pdf, setPDF] = useState()
  const [name, setName] = useState()

  const handlePDF = (e) => {
    setPDF(e.target.files[0])
  }

  const handleName = (e) => {
    setName(e.target.value)
  }

  console.log(pdf)
  const uploadPDF = async (e) => {
    setLoading(true)
    formData.append('pdf', pdf)
    formData.append('name', name)
    await axios({
      method: 'post',
      url: `${API}/upload/pdf`,
      data: formData,
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false)
          setPDF('')
          setName('')
          swal('PDF has been uploaded', '', 'success')
          return
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        swal('Cannot upload PDF', '', 'error')
      })
  }

  const showLoading = () =>
    loading && (
      <div>
        <h4>Uploading PDF, Please wait...</h4>
      </div>
    )

  return (
    <div className="container gv-mb">
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <h3 className="text-weight-light">Upload Catalogues</h3>
      </div>
      <label className="btn btn-block btn-info">
        <input
          onChange={handlePDF}
          type="file"
          name="pdf"
          placeholder="choose a file"
        />
      </label>
      <input
        onChange={handleName}
        class="form-control"
        type="text"
        placeholder="Enter catalogue name"
      />

      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button onClick={uploadPDF} className="btn btn-success">
          UPLOAD
        </button>
        {showLoading()}
      </div>
    </div>
  )
}

export default UploadCatalogues

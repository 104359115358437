import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { loadCart } from '../core/CartHelper'
import { AuthContext } from '../../AuthProvider'

const CartDropdown = () => {
  const [products, setProducts] = useState([])
  const r = useContext(AuthContext)

  useEffect(() => {
    setProducts(loadCart())
  }, [r.reload])
  const history = useHistory()
  return (
    <div>
      <div className="dropdown">
        <button
          type="button"
          style={{
            margin: '0px',
            padding: '0px',
            border: '0px',
            textDecoration: 'none',
          }}
          className="btn"
          data-toggle="dropdown"
        >
          <img
            style={{ zIndex: '5250' }}
            src={`${process.env.PUBLIC_URL}/images/icon-header-02.png`}
            alt=""
          />
          <span className="badge badge-pill badge-danger">
            {products === undefined ? 0 : products.length}
          </span>
        </button>
        <div className="dropdown-menu">
          {products === undefined || products.length === 0 ? (
            <center>Your cart is empty!</center>
          ) : (
            products.map((product) => (
              <div className="row cart-detail">
                <div className="col-lg-4 col-sm-4 col-4 cart-detail-img">
                  <img src={product.photoURL} />
                </div>
                <div className="col-lg-8 col-sm-8 col-8 cart-detail-product">
                  <p>{product.name.join(' ')}</p>
                  {/* <span className="price text-info"> $250.22</span>{' '} */}
                  {/* <span className="count"> Quantity:01</span> */}
                </div>
              </div>
            ))
          )}
          {products === undefined || products.length === 0 ? (
            <></>
          ) : (
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-6 text-center checkout">
                <button
                  onClick={() => {
                    history.push('/cart')
                  }}
                  className="btn btn-primary btn-dropdown bg-dark text-white"
                >
                  View Cart
                </button>
              </div>
              <div className="col-lg-6 col-sm-6 col-6  text-center checkout">
                <button
                  onClick={() => {
                    history.push('/checkout')
                  }}
                  className="btn btn-primary btn-dropdown bg-dark text-white"
                >
                  Checkout
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CartDropdown

import React, { useState, useEffect } from 'react'
import firebase from '../../firebase'
import DisplayBS from './DisplayBS'

const ManageBrandsAndCats = () => {
  var db = firebase.firestore()
  const [brands, setBrands] = useState([])
  const [categories, setCategories] = useState([
    {
      brand: '',
      cats: [],
    },
  ])
  const [loading, setLoading] = useState(false)

  const preload = async () => {
    const citiesRef = db.collection('brands')
    const snapshot = await citiesRef.get()

    setBrands(snapshot.docs.map((doc) => doc.data()))
    console.log(snapshot.docs)

    snapshot.docs.map(async (doc) => {
      let getCategory = await db
        .collection('sub-categories')
        .where('brand', '==', doc.data().name)
      console.log(getCategory.docs)
    })
  }

  useEffect(() => {
    setLoading(true)
    preload()
  }, [])
  console.log(categories)
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h4 className="font-weight-light mt-2">
          Manage brands and subcategories
        </h4>
        <small>Click on View products to delete only specific products</small>
      </div>
      <div className="container gv-mb">
        <div className="row">
          {brands.map((brand) => (
            <div className="col-lg-6 mb-2 mt-2 col-md-6 col-sm-12">
              <DisplayBS brand={brand} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ManageBrandsAndCats

import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { Reloader } from "../../AuthProvider";
import { API } from "../../backend";
import axios from "axios";
import { cartEmpty, loadCart } from "./CartHelper";
import { useEffect } from "react";

const NewCheckout = () => {
  const [loading, setLoading] = useState(false);
  var history = useHistory();
  const r = useContext(Reloader);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    city: "",
    state: "",
    phoneNumber: "",
    email: "",
    cartItems: [],
  });

  const {
    firstName,
    lastName,
    companyName,
    city,
    state,
    phoneNumber,
    email,
    cartItems,
  } = userInfo;

  useEffect(() => {
    setUserInfo({ ...userInfo, cartItems: loadCart() });
  }, []);

  const handleFirstNameChange = (e) => {
    setUserInfo({ ...userInfo, firstName: e.target.value });
  };

  const handleLastNameChange = (e) => {
    setUserInfo({ ...userInfo, lastName: e.target.value });
  };

  const handleCompanyNameChange = (e) => {
    setUserInfo({ ...userInfo, companyName: e.target.value });
  };

  const handleCityChange = (e) => {
    setUserInfo({ ...userInfo, city: e.target.value });
  };

  const handleStateChange = (e) => {
    setUserInfo({ ...userInfo, state: e.target.value });
  };

  const handleEmailChange = (e) => {
    setUserInfo({ ...userInfo, email: e.target.value });
  };

  const handlePhoneNumberChange = (e) => {
    setUserInfo({ ...userInfo, phoneNumber: e.target.value });
  };

  const createOrder = (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();
    // formData.append('items', products)
    // formData.append('firstName', firstName)
    // formData.append('lastName', lastName)
    // formData.append('city', city)
    // formData.append('state', state)
    // formData.append('email', email)
    // formData.append('phoneNumber', phoneNumber)
    axios({
      method: "post",
      url: `${API}/create/guest/order`,
      data: userInfo,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          cartEmpty(() => {
            r();
            swal(
              `${response.data.message}`,
              "Thanks for shopping with us",
              "success"
            );
            history.push("/");
          });
        } else {
          setLoading(false);
          swal("Could not process your order", "", "error");
        }
      })
      .catch((err) => {
        setLoading(false);
        swal("Could not process your order", "", "error");
      });
  };

  return (
    <div className="container">
      <br />
      <br />
      <center>
        <h5>
          <strong>
            Enter the details below, we'll get back to you with qouatation of
            the selected products through SMS and email.
          </strong>
        </h5>
      </center>
      <hr />
      <br />
      <form onSubmit={createOrder}>
        <div class="form-row">
          <div class="col-md-4 mb-3">
            <label for="validationDefault01">First name</label>
            <input
              onChange={handleFirstNameChange}
              type="text"
              class="form-control"
              id="validationDefault01"
              placeholder="First name"
              required
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="validationDefault02">Last name</label>
            <input
              onChange={handleLastNameChange}
              type="text"
              class="form-control"
              id="validationDefault02"
              placeholder="Last name"
              required
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="validationDefaultUsername">Company Name</label>
            <div class="input-group">
              <div class="input-group-prepend"></div>
              <input
                onChange={handleCompanyNameChange}
                type="text"
                class="form-control"
                id="validationDefaultUsername"
                placeholder="Company name"
                aria-describedby="inputGroupPrepend2"
                required
              />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 mb-3">
            <label for="validationDefault03">Email</label>
            <input
              onChange={handleEmailChange}
              type="email"
              class="form-control"
              id="validationDefault03"
              placeholder="abc@example.com"
              required
            />
            <small id="passwordHelpBlock" class="form-text text-muted">
              You will be notified on this email address with the qoutation.
            </small>
          </div>
          <div class="col-md-6 mb-3">
            <label for="validationDefault03">Phone Number</label>
            <PhoneInput
              country={"in"}
              value={phoneNumber}
              onChange={(value) =>
                setUserInfo({
                  ...userInfo,
                  phoneNumber: "+" + value,
                })
              }
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
            />
            <small id="passwordHelpBlock" class="form-text text-muted">
              You will be notified on this phone number with the qoutation.
            </small>
          </div>
          <div class="col-md-3 mb-3">
            <label for="validationDefault04">City</label>
            <input
              onChange={handleCityChange}
              type="text"
              class="form-control"
              id="validationDefault04"
              placeholder="State"
              required
            />
          </div>
          <div class="col-md-3 mb-3">
            <label for="validationDefault05">State</label>
            <input
              onChange={handleStateChange}
              type="text"
              class="form-control"
              id="validationDefault05"
              placeholder="Zip"
              required
            />
          </div>
        </div>
        {/* <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="invalidCheck2"
              required
            />
            <label class="form-check-label" for="invalidCheck2">
              Agree to terms and conditions
            </label>
          </div>
        </div> */}
        {loading ? (
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <button class="btn btn-dark" type="submit">
            Request Qoute
          </button>
        )}
      </form>
    </div>
  );
};

export default NewCheckout;

import React, { useState, useEffect } from 'react'
import firebase from '../../firebase'
import ProductCard from '../Cards/ProductCard'

const ShowAllProducts = (props) => {
  var db = firebase.firestore()
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [subCategories, setsubCategories] = useState([])
  const [subCategory, setsubCategory] = useState()
  const [subCategoryLoading, setsubCategoryLoading] = useState(false)

  const loadSubcategories = async () => {
    setsubCategoryLoading(true)
    const c = await db
      .collection('sub-categories')
      .where('brand', '==', props.brandName)
      .get()
    setsubCategories(c.docs.map((doc) => doc.data()))
    setsubCategoryLoading(false)
  }

  const SubCategoryHandler = async (e) => {
    setLoading(true)
    setsubCategory(e.target.value)
    var result = await db
      .collection('products')
      .where('brand', '==', props.brandName)
      .where('subcategory', '==', e.target.value)
      .orderBy('sequence')
      .get()
    setProducts(result.docs.map((doc) => doc.data()))
    setLoading(false)
  }

  console.log(subCategories)

  const loadProducts = async () => {
    var result = await db
      .collection('products')
      .where('brand', '==', props.brandName)
      .orderBy('sequence')
      .get()
    setProducts(result.docs.map((doc) => doc.data()))
    setLoading(false)
  }
  console.log(props)
  console.log(products)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setLoading(true)
    loadSubcategories()
    loadProducts()
  }, [props.brandName])

  return (
    <div className="container mt-3">
      <div className="form-group">
        <select
          value={subCategory}
          onChange={SubCategoryHandler}
          className="form-control"
          placeholder="Category"
        >
          <option>Select Sub Category</option>
          {subCategoryLoading ? (
            <option disabled>Loading...</option>
          ) : (
            subCategories &&
            subCategories.map((cate, index) => {
              return (
                <option value={cate.name} key={index}>
                  {cate.name}
                </option>
              )
            })
          )}
        </select>
      </div>
      <div className="row">
        {loading ? (
          <center style={{ textAlign: 'center', width: '100%' }}>
            <div className="spinner-border text-dark mt-4" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        ) : products.length === 0 ? (
          <center style={{ textAlign: 'center', width: '100%' }}>
            <h3>No Products !</h3>
          </center>
        ) : (
          products.map((product) => (
            <div className="col-lg-3 col-sm-12 col-md-6 mt-2">
              <ProductCard product={product} />
            </div>
          ))
        )}
      </div>
      <br />
    </div>
  )
}
export default ShowAllProducts

import React, { useState } from 'react'
import { useEffect } from 'react'
import firebase from '../../firebase'

const PDFViewers = () => {
  const [pdfViewers, setpdfViewers] = useState([])
  const [loading, setloading] = useState(false)

  var db = firebase.firestore()

  const getViewers = async () => {
    const viewers = await db.collection('pdf_viewers').get()
    setpdfViewers(viewers.docs.map((doc) => doc.data()))
    setloading(false)
  }

  console.log(pdfViewers)

  useEffect(() => {
    setloading(true)
    getViewers()
  }, [])

  return (
    <div className="gv-mb">
      <div className="container">
        <center>
          <br />
          <h4>PDF Viewers</h4>
        </center>
        {loading ? (
          <center>
            <p>Loading Viewers</p>
          </center>
        ) : (
          <table class="table">
            <thead class="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Company Name</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>
              {pdfViewers.map((viwer, index) => (
                <tr>
                  <th scope="row">{index + 1}</th>
                  <td>{viwer.fullName}</td>
                  <td>{viwer.companyName}</td>
                  <td>{viwer.phoneNumber}</td>
                  <td>{viwer.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
export default PDFViewers

import React, { useContext } from 'react'
import { AuthContext } from '../../AuthProvider'
import firebase from '../../firebase'
import { Link } from 'react-router-dom'

const AdminDashboard = () => {
  var user = firebase.auth().currentUser
  var name, email, photoUrl, uid, emailVerified

  if (user != null) {
    name = user.displayName
    email = user.email
    photoUrl = user.photoURL
    emailVerified = user.emailVerified
    uid = user.uid // The user's ID, unique to the Firebase project. Do NOT use
    // this value to authenticate with your backend server, if
    // you have one. Use User.getToken() instead.
  }
  const LeftSide = () => (
    <div className="card shadow">
      <h4 className="card-header bg-info text-white">Choose</h4>
      <ul className="list-group">
        <li className="list-group-item">
          <Link to="/admin/orders/new" className="nav-link text-dark">
            Manage Orders
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/admin/create/brand" className="nav-link text-dark">
            Create Brand
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/admin/create/subcategory" className="nav-link text-dark">
            Create Sub-Category
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/admin/create/product" className="nav-link text-dark">
            Create Products
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/admin/upload/catalogue" className="nav-link text-dark">
            Upload Catalogues
          </Link>
        </li>
        <li className="list-group-item">
          <Link
            to="/admin/manage/brands/manage/subcategories"
            className="nav-link text-dark"
          >
            Manage Brands & Sub-Categories
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/admin/manage/slider" className="nav-link text-dark">
            Manage Sldier Images
          </Link>
        </li>
        <li className="list-group-item">
          <Link
            to="/admin/manage/featured/products"
            className="nav-link text-dark"
          >
            Manage Featured Products
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/admin/manage/vacancies" className="nav-link text-dark">
            Offer Vacancies
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/admin/manage/dealers" className="nav-link text-dark">
            View Dealer Requests
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/admin/view/pdf_viewers" className="nav-link text-dark">
            View PDF Viewers
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/admin/manage/events" className="nav-link text-dark">
            Add Events
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/admin/manage/gallery" className="nav-link text-dark">
            Add Gallery
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/admin/manage/offer" className="nav-link text-dark">
            Manage Offer Alert
          </Link>
        </li>
      </ul>
    </div>
  )

  const RightSide = () => (
    <div className="card shadow mb-4">
      <h4 className="card-header bg-danger text-white">Admin Information</h4>
      <ul className="list-group">
        <li className="list-group-item">
          <span className="badge badge-success mr-2">Name:</span>
          {name}
        </li>
        <li className="list-group-item">
          <span className="badge badge-success mr-2">Email:</span>
          {email}
        </li>
        <li className="list-group-item">
          <span className="badge badge-danger mr-2">Admin Area</span>
        </li>
      </ul>
    </div>
  )
  return (
    <>
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        <h6>Welcome, Admin!</h6>
      </div>
      <div className="container mt-5" style={{ marginBottom: '7rem' }}>
        <div className="row" id="r">
          <div className="col-sm-12 col-lg-8 col-md-8">{LeftSide()}</div>
          <div className="col-sm-12 col-lg-4 col-md-4 mt-2">{RightSide()}</div>
        </div>
      </div>
    </>
  )
}

export default AdminDashboard

import React, { useEffect, useState, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { loadCart } from '../core/CartHelper'
import { signout } from '../firebase/authFunctions'
import { AuthContext } from '../../AuthProvider'

const UserDropdown = () => {
  const history = useHistory()
  const user = useContext(AuthContext)
  const logout = (e) => {
    e.preventDefault()
    let resp = signout()
    if (resp) {
      history.push('/signin')
    }
  }
  return (
    <div
      className="dropdown"
      style={{
        width: '10px',
      }}
    >
      <button
        type="button"
        style={{ margin: '0px', padding: '0px' }}
        className="btn"
        data-toggle="dropdown"
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/icon-header-01.png`}
          alt=""
        />
      </button>
      <div className="dropdown-menu" id="user-dropdown-menu">
        <div className="row cart-detail">
          {user.isAdmin ? (
            <Link style={{ textDecoration: 'none' }} to="/admin">
              <div className="col-lg-12 col-sm-12 col-12 cart-detail-product">
                <p>Admin Dashboard</p>
              </div>
            </Link>
          ) : (
            <Link style={{ textDecoration: 'none' }} to="/user">
              <div className="col-lg-12 col-sm-12 col-12 cart-detail-product">
                <p>Your Orders</p>
              </div>
            </Link>
          )}
        </div>
        <div className="row cart-detail">
          <div className="col-lg-12 col-sm-12 col-12 cart-detail-product">
            <p onClick={logout}>Logout</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserDropdown

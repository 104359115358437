import React, { useState, useContext } from 'react'
import { signIn } from '../firebase/authFunctions'
import { AuthContext } from '../../AuthProvider'
import { useHistory } from 'react-router-dom'
import firebase from '../../firebase'
import BannerCard from '../Cards/BannerCard'

const SignIn = (props) => {
  let callingFromCheckout = props.callingFromCheckout
  let setStepOneLogin = props.setStepOneLogin
  let setStepTwoLogin = props.setStepTwoLogin
  const history = useHistory()
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
    error: '',
  })
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [response, setResponse] = useState()

  const { email, password, error } = userInfo

  const handleChange = (name) => (event) => {
    setUserInfo({ ...userInfo, error: false, [name]: event.target.value })
  }

  const errorMessage = () => {
    if (error) {
      return (
        <div>
          <h5 className="alert alert-danger">{response}</h5>
        </div>
      )
    }
  }

  const performRedirect = () => {
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        // Confirm the user is an Admin.
        if (!!idTokenResult.claims.admin) {
          // Show admin UI.
          history.push('/admin')
        } else {
          // Show regular user UI.
          if (callingFromCheckout) {
            setStepTwoLogin(true)
            setStepOneLogin(true)
          } else {
            history.push('/user')
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(userInfo)
    setLoading(true)
    await signIn(userInfo).then((response) => {
      if (response) {
        console.log(response)
        setLoading(false)
        setResponse(response.message)
        setUserInfo({ ...userInfo, error: true, email: '', password: '' })
      } else {
        setRedirect(true)
        setLoading(false)
        console.log('yaha aaya', response)
      }
    })
  }

  return (
    <div>
      <div className="container mt-5">
        <h3>Login</h3>
        {errorMessage()}
        <form>
          <div className="form-group">
            <label for="exampleInputEmail1">Email address</label>
            <input
              value={email}
              onChange={handleChange('email')}
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input
              value={password}
              onChange={handleChange('password')}
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
            />
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
            />
            <label className="form-check-label" for="exampleCheck1">
              Check me out
            </label>
          </div>
          {loading ? (
            <center style={{ textAlign: 'center', width: '100%' }}>
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </center>
          ) : (
            <button
              onClick={handleSubmit}
              type="submit"
              className="btn btn-primary"
            >
              Submit
            </button>
          )}
        </form>
        <br />
        <br />
        <br/>
        {redirect ? performRedirect() : <></>}
      </div>
    </div>
  )
}

export default SignIn

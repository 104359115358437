import React from "react";

const AboutTeam = () => {
  return (
    <div className="container">
      <div className="container mt-5 mb-4" style={{ textAlign: "center" }}>
        <h3 className="headings">ABOUT KAY TEE INTERNATIONAL</h3>
        <br />
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <img
              src={`${process.env.PUBLIC_URL}/images/about.jpg`}
              className="img-fluid shadow"
              alt=""
            />
          </div>
          <div className="col-lg-8 mt-2 col-md-8 col-sm-12">
            <p>
              Kay Tee International is one of Mumbai’s largest channel partner
              of reputed Indian & International brands in Bathroom Fittings,
              Sanitaryware & Plumbing Fittings. With a humble beginning in 2007
              and a king size dream, we at Kay Tee aim to change the pattern and
              response to the customers. <br /> <br /> Kay Tee Group’s
              overriding objective is to move from the traditional role of
              reactionary marketing to a new strategic pro-active brand building
              role. The only thing that is constant is change, and India’s
              landscape is constantly changing and presenting new opportunities
              while simultaneously taking away others.
              <br /> <br />
              At Kay Tee, we are constantly striving towards a more efficient,
              cooperative and participative relationship with our customers and
              (as well as) suppliers. We will take a new untraditional approach
              of distribution throughout India. We provide a basket of products
              under one roof to meet any project or maintenance requirements.
              Our management philosophy is based on trust, responsibility and
              mutual respect. People who work with Kay Tee join our team because
              we have an environment that encourages creativity and achievement.
              Our sales team is a great blend of young knowledgeable people and
              seasoned veterans.
              <br /> <br />
              We believe with these core values and business expertise Kay Tee
              Group will continue on a path of profitable growth and will be
              recognized as one of the premier channel partners and sales &
              marketing company in India.
            </p>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <h4 className="headings">MEET THE CEO</h4>
      </div>
      <div className="row" id="r">
        <div className="col-lg-7 col-sm-12 mt-2">
          <p className="p-align">
            <h6 className=" name">Sunill Talreja (Co-Founder & CEO)</h6>
            Thanks for visiting our web site. <br /> I sincerely hope you enjoy
            browsing and learning more about our company and our many fine
            products as well as suppliers. <br /> We are extremely enthusiastic
            about the opportunities and challenges that lie ahead. <br /> Kay
            Tee Group was founded way back in 1992, by Mr. Haresh Talreja and
            Myself. <br /> Kay Tee International is a unique company acting as a
            manufacturers’- direct wholesale distribution company in Mumbai,
            while also owning and representing manufacturers with different
            brands throughout the State. <br /> Our goal at Kay Tee
            International is to bring to you the highest quality products at a
            true price value. <br /> As we grow we intend to expand our
            portfolio of building materials so we can offer our additional
            products in various categories. <br /> Our overall strategy at Kay
            Tee International is to bring to you good and exciting brands that
            complement our current portfolio of brands and add value to the
            customers. <br /> We are enthusiastic and excited about the
            possibilities and look forward to a great future. We look forward to
            exceeding your expectations while enhancing your overall buying
            experience. <br />
            <br />
            Thanks for stopping by, <br /> <br />
            <strong>
              Sunill Talreja, <br /> Co-Founder & CEO
              <br /> Kay Tee Group
            </strong>
          </p>
        </div>
        <div
          className="col-lg-5 mt-5 col-sm-12"
          style={{ textAlign: "center" }}
        >
          <img
            className="img-fluid shadow-lg"
            src={`${process.env.PUBLIC_URL}/images/CEO.jpeg`}
            alt=""
          />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-lg-3 col-sm-12" style={{ textAlign: 'center' }}>
          <img src="https://via.placeholder.com/250x250" alt="" />
        </div>
        <div className="col-lg-9 col-sm-12 mt-2">
          <p className="p-align">
            <h6 className="name">Sunil Talreja (CEO-Founder)</h6>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Necessitatibus molestiae, error repellendus dolore vitae culpa
            adipisci reprehenderit quasi facilis ab unde labore iusto ad odio
            asperiores voluptatum nostrum in ipsa minima? Modi commodi velit
            quisquam, doloribus delectus recusandae voluptate autem architecto
            ducimus, asperiores esse voluptatum quo iure animi. Optio suscipit
            odit voluptatibus officiis cumque explicabo recusandae. Debitis sint
            accusamus possimus necessitatibus ex fuga nostrum, tempore dicta
            voluptatum magnam dolores eligendi error harum ea provident incidunt
            corrupti, inventore in fugit enim impedit eaque porro! Enim
            exercitationem culpa accusamus recusandae ut assumenda,
            reprehenderit ipsam ad porro dicta maxime. Nemo ad aliquam adipisci.
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default AboutTeam;

import React, { useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../AuthProvider'
import firebase from '../../firebase'

const AdminRoute = ({ component: Comp, ...rest }) => {
  const user = useContext(AuthContext)
  let isItAdmin = localStorage.getItem('admin')
  console.log(user)
  return (
    <Route
      {...rest}
      render={(props) =>
        user && isItAdmin ? <Comp {...props} /> : <Redirect to="/" />
      }
    />
  )
}

export default AdminRoute

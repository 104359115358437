import React, { useState, useEffect } from 'react'
import firebase from 'firebase'
import swal from 'sweetalert'

const OfferVacancies = () => {
  var db = firebase.firestore()
  const [vac, setVac] = useState([])
  const [job, setJob] = useState({
    title: '',
    description: '',
    location: '',
    experience: '',
    vacancies: '',
  })

  const deleteJob = async (e, id) => {
    e.preventDefault()
    await db
      .collection('jobs')
      .doc(id)
      .delete()
      .then(() => {
        swal('Deleted Succesfully', '', 'success')
      })
  }

  useEffect(() => {
    preloadJobs()
  }, [])

  const preloadJobs = async () => {
    let result = await db.collection('jobs').get()
    setVac(result.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
  }

  const { title, description, location, experience, vacancies } = job

  const handleTitle = (e) => {
    setJob({ ...job, title: e.target.value })
  }

  const handleDescription = (e) => {
    setJob({ ...job, description: e.target.value })
  }

  const handleLocation = (e) => {
    setJob({ ...job, location: e.target.value })
  }

  const handleExperience = (e) => {
    setJob({ ...job, experience: e.target.value })
  }

  const handleVacancies = (e) => {
    setJob({ ...job, vacancies: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await db
      .collection('jobs')
      .doc()
      .set({
        title: title,
        description: description,
        location: location,
        experience: experience,
        vacancies: vacancies,
      })
      .then(() => {
        swal('Job Offer has been posted!', '', 'success')
        setJob({
          ...job,
          title: '',
          description: '',
          location: '',
          experience: '',
          vacancies: '',
        })
      })
  }

  return (
    <div className="gv-mb">
      <div className="container mt-5">
        <form action="">
          <div class="form-group">
            <label for="exampleFormControlInput1">Job Title</label>
            <input
              onChange={handleTitle}
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Job Title"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">Job Description</label>
            <input
              onChange={handleDescription}
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Job Description"
            />
          </div>{' '}
          <div class="form-group">
            <label for="exampleFormControlInput1">Vacancies</label>
            <input
              onChange={handleVacancies}
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Number of vacancies"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">Experience</label>
            <input
              onChange={handleExperience}
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Experience"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">Location</label>
            <input
              onChange={handleLocation}
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Location"
            />
          </div>
          <button onClick={handleSubmit} type="submit" className="btn btn-dark">
            SUBMIT
          </button>
        </form>
      </div>
      {vac.length === 0 ? (
        <></>
      ) : (
        <div className="container" style={{ textAlign: 'center' }}>
          <h5>Manage Current Vacancies</h5>
          {vac.map((v) => (
            <div className="card shadow">
              <div className="card-body">
                <div className="card-tex">{v.title}</div>
                <button
                  onClick={(e) => {
                    deleteJob(e, v.id)
                  }}
                  className="btn btn-danger"
                >
                  DELETE
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default OfferVacancies

import React, { useState, useEffect } from 'react'
import ProductCard from '../Cards/ProductCard'
import firebase from '../../firebase'
import BannerCard from '../Cards/BannerCard'

const SubCategoriesPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  var subCategoryName
  var brand
  var db = firebase.firestore()
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const fetchProducts = async () => {
    const subcat = await db
      .collection('products')
      .where('brand', '==', brand)
      .where(
        'subcategory',
        '==',
        subCategoryName === 'FRP/ GRP Manhole Covers,WGC & Gratings'
          ? 'FRP/ GRP Manhole Covers,WGC & Gratings'
          : subCategoryName.replace(',', '/'),
      )
      .orderBy('sequence')
      .get()
    console.log(subCategoryName)
    setProducts(subcat.docs.map((doc) => doc.data()))
    // const c = await db
    //   .collection('products')
    //   .where('product_id', '==', '13252000')
    //   .get()
    // c.docs.map((prod) => console.log(prod.name, '=>', prod.id))
  }

  console.log(products)

  useEffect(() => {
    setLoading(true)
    const fetchCurrentName = async () => {
      brand = await localStorage.getItem('brand')
      subCategoryName = await localStorage.getItem('subcategoryname')
      console.log(subCategoryName)
      fetchProducts().then(() => {
        setLoading(false)
      })
    }
    fetchCurrentName()
  }, [])

  return (
    <div>
      <BannerCard bannerName={localStorage.getItem('subcategoryname')} />
      <h1>
        <div className="mt-3 gv-mb">
          {loading ? (
            <center style={{ textAlign: 'center', width: '100%' }}>
              <div className="spinner-border text-dark mt-4" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </center>
          ) : (
            <div className="container">
              <div className="row">
                {products.length === 0 ? (
                  <center style={{ textAlign: 'center', width: '100%' }}>
                    <h5>No Products in this Sub Category</h5>
                  </center>
                ) : (
                  products.map((product, index) => (
                    <div className="col-lg-3 mb-3 col-md-6 col-sm-12 ">
                      <ProductCard product={product} />
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
        </div>
      </h1>
    </div>
  )
}
export default SubCategoriesPage

import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../AuthProvider";
import { useHistory, Link, withRouter } from "react-router-dom";
import { signout } from "../firebase/authFunctions";
import firebase from "../../firebase";
import CartDropdown from "./CartDropdown";
import UserDashboard from "../user/UserDashboard";
import UserDropdown from "./UserDropdown";
import swal from "sweetalert";

const db = firebase.firestore();

const currentTab = (history, path) => {
  if (history.location.pathname === path) {
    return "nav-link active";
  } else {
    return "nav-link";
  }
};
const Navbar = ({ history }) => {
  const [offer, setOffer] = useState();
  const [change, setChange] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [searchName, setSearchName] = useState();
  var user = useContext(AuthContext);
  //console.log(user.currentUser)
  const [values, setValues] = useState({
    brands: [],
    categories: [],
  });

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const searchHandler = (e) => {
    setSearchName(e.target.value);
  };

  const swalSearch = (e) => {
    e.preventDefault();
    swal({
      text: "Search for a product. e.g. Spray.",
      content: "input",
      button: {
        text: "Search!",
        closeModal: true,
      },
    }).then((searchName) => {
      if (searchName === undefined) {
        alert("Search field cannot be empty!");
      } else {
        let s = toTitleCase(searchName);
        history.push({
          pathname: "/search",
          data: s,
        });
      }
    });
  };

  const onClickSearch = (e) => {
    e.preventDefault();
    if (searchName === undefined) {
      alert("Search field cannot be empty!");
    } else {
      let s = toTitleCase(searchName);
      history.push({
        pathname: "/search",
        data: s,
      });
    }
  };

  const { brands, categories } = values;

  const fetchBrandsAndCategories = async () => {
    const citiesRef = db.collection("brands");
    const snapshot = await citiesRef.get();
    const subcat = await db.collection("sub-categories").get();
    setValues({
      ...values,
      brands: snapshot.docs.map((doc) => doc.data()),
      subcategory: subcat.docs.map((doc) => doc.data()),
    });
  };

  const fetchOffer = async () => {
    const offer = await db.collection("offers").get();
    setOffer(offer.docs.map((doc) => doc.data()));
  };

  const logout = (e) => {
    e.preventDefault();
    let resp = signout();
    if (resp) {
      history.push("/signin");
    }
  };

  useEffect(() => {
    fetchBrandsAndCategories();
    fetchOffer();
    window.onscroll = function () {
      myFunction();
    };
    var navbar = document.getElementById("navbar");
    var alert = document.getElementById("alert");
    var sticky = navbar.offsetTop;
    function myFunction() {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
      // var winScroll =
      //   document.body.scrollTop || document.documentElement.scrollTop
      // var height =
      //   document.documentElement.scrollHeight -
      //   document.documentElement.clientHeight
      // var scrolled = (winScroll / height) * 100
      // document.getElementById('myBar').style.width = scrolled + '%'
    }
  }, []);

  return (
    <>
      <div>
        {offer === undefined ? (
          <div
            className="custom-alert"
            id="alert"
            style={{ display: clicked ? "none" : "" }}
          >
            <div className="row">
              <div className="col-lg-6 ml-0 pl-0">
                Fetching offers for you...
              </div>
              <div className="col-lg-6">
                {" "}
                <p className="m-0 mr-2 p-0 d-inline phone-no">
                  <span className="mr-2">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-telephone-fill"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"
                      />
                    </svg>
                  </span>
                  <a style={{ color: "white" }} href="tel:02226410733 ">
                    {" "}
                    02226410733{" "}
                  </a>
                  /
                  <a style={{ color: "white" }} href="tel:02226410734">
                    02226410734
                  </a>
                </p>
                <p className="m-0 p-0 d-inline">
                  <span className="ml-3 mr-2">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-envelope-fill"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
                      />
                    </svg>
                  </span>
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="mailto:sales@kayteegroup.in"
                  >
                    <span className="ml-3 mr-2">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-envelope-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
                        />
                      </svg>
                    </span>
                    sales@kayteegroup.in
                  </a>
                </p>
              </div>
            </div>
          </div>
        ) : (
          offer.map((off) => (
            <div
              className="custom-alert"
              id="alert"
              style={{ display: clicked ? "none" : "" }}
            >
              <div className="row">
                <div className="col-lg-6 ml-0 pl-0 phone-no">
                  {!off.name ? "" : off.name}
                </div>
                <div className="col-lg-6">
                  {" "}
                  <p className="m-0 mr-2 p-0 d-inline phone-no">
                    <span className="mr-2">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-telephone-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"
                        />
                      </svg>
                    </span>
                    <a style={{ color: "white" }} href="tel: 02226410733 ">
                      {" "}
                      02226410733{" "}
                    </a>{" "}
                    /
                    <a style={{ color: "white" }} href="tel:02226410734">
                      {" "}
                      02226410734
                    </a>
                  </p>
                  <p className="m-0 p-0 d-inline">
                    <span className="ml-3 mr-2">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-envelope-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
                        />
                      </svg>
                    </span>
                    <a
                      style={{ textDecoration: "none", color: "white" }}
                      href="mailto:sales@kayteegroup.in"
                    >
                      {" "}
                      sales@kayteegroup.in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ))
        )}
        <div className="d-flex logo bd-highlight">
          <div className="mr-auto mt-1 pt-2 pl-2 pr-2 pb-0 bd-highlight">
            <img
              style={{ cursor: "pointer" }}
              className="img-fluid"
              onClick={() => {
                history.push("/");
              }}
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt=""
            />
          </div>
          <form class="form-inline mt-2 large-nav">
            <input
              onChange={searchHandler}
              class="form-control capitalize mt-4"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button onClick={onClickSearch} class="btn" type="submit">
              <img
                style={{ marginTop: "1.7rem" }}
                className="search"
                height="38px"
                width="40px"
                src={`${process.env.PUBLIC_URL}/images/search.png`}
                alt=""
              />
            </button>
          </form>
          <div className="p-2 bd-highlight large-nav mt-5 ml-3 ">
            {user.currentUser ? (
              <UserDropdown />
            ) : (
              <>
                <div style={{ height: "6px" }}></div>

                {/* <Link
                  style={{ textDecoration: 'none', paddingTop: '9px' }}
                  className="text-dark mt-5"
                  to="/signup"
                >
                  REGISTER
                </Link> */}
              </>
            )}
          </div>

          <div className="p-2 bd-highlight large-nav mt-5 mr-5 ">
            <CartDropdown />
          </div>
        </div>

        <nav
          id="navbar"
          className="navbar shadow-sm sticky-top  navbar-expand-lg navbar-light  "
        >
          <button
            onClick={() => {
              setChange(!change);
            }}
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className={change ? "change" : ""} id="mn">
              <div class="bar1"></div>
              <div class="bar2"></div>
              <div class="bar3"></div>
            </div>

            {/* <span className="navbar-toggler-icon"></span> */}
          </button>

          <span>
            <div class="d-flex flex-row-reverse bd-highlight">
              <div>
                <div className="mobile-nav">
                  <br />
                  <CartDropdown />
                </div>
              </div>
              <div class="mt-3 p-1 bd-highlight mobile-nav">
                <img
                  onClick={swalSearch}
                  className="search"
                  height="38px"
                  width="40px"
                  src={`${process.env.PUBLIC_URL}/images/search.png`}
                  alt=""
                />
              </div>
            </div>
          </span>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <div className="mobile-nav">
                <li className="nav-item">
                  <Link className={currentTab(history, "/")} to="/">
                    {user.currentUser ? (
                      <>
                        {user.isAdmin ? (
                          <li className="nav-item">
                            <Link
                              to="/admin"
                              className={currentTab(history, "/admin")}
                            >
                              Admin Dashboard
                            </Link>
                          </li>
                        ) : (
                          <li className="nav-item">
                            <Link
                              className={currentTab(history, "/user")}
                              to="/user"
                            >
                              View Orders
                            </Link>
                          </li>
                        )}
                        <li className="nav-item">
                          <Link
                            onClick={logout}
                            className={currentTab(history, "/")}
                            to="/"
                          >
                            Logout
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        {/* <li className="nav-item">
                          <Link
                            className={currentTab(history, '/signin')}
                            to="/signin"
                          >
                            Login
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={currentTab(history, '/signup')}
                            to="/signup"
                          >
                            Register
                          </Link>
                        </li> */}
                      </>
                    )}
                  </Link>
                </li>
              </div>
              <li className="nav-item">
                <Link className={currentTab(history, "/")} to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className={currentTab(history, "/all")} to="/all">
                  All Products
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={currentTab(history, "/catalogue")}
                  to="/catalogue"
                >
                  Catalogues and Price Lists
                </Link>
              </li>
              {/* company dropdown */}
              <li className="nav-item">
                <div class="dropdown-navbar" style={{ zIndex: "4" }}>
                  <span>
                    Company
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-arrow-down-short"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 7.646a.5.5 0 0 1 .708 0L8 10.293l2.646-2.647a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M8 4.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                  </span>
                  <div class="dropdown-content-navbar shadow">
                    <Link to="/about">About</Link>
                    <br />
                    <Link to="/career">Career</Link>
                    <br />
                    <Link to="/events">Events</Link>
                    <br />
                    <Link to="/gallery">Gallery</Link>
                  </div>
                </div>
              </li>
              {/* partners */}
              <li className="nav-item">
                <div class="dropdown-navbar">
                  <span>
                    Partners
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-arrow-down-short"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 7.646a.5.5 0 0 1 .708 0L8 10.293l2.646-2.647a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M8 4.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                  </span>
                  <div
                    class="dropdown-content-navbar shadow"
                    style={{ width: "270px" }}
                  >
                    <Link to="/become-a-dealer">
                      <p style={{ textAlign: "left" }}>
                        Become a Dealer/Sub Dealer
                      </p>
                    </Link>
                  </div>
                </div>
              </li>

              <li className="nav-item">
                <Link className={currentTab(history, "/contact")} to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {/* <div class="progress-container sticky large-nav">
        <div class="progress-bar" id="myBar"></div>
      </div> */}
    </>
  );
};

export default withRouter(Navbar);

import React from 'react'
import Guests from './Guests'
import RegisteredUsers from './RegisteredUsers'

const ManagerOrder = () => {
  return (
    <div className="container mt-2 gv-mb">
      <div>
        <h4 className=" font-weight-bold" style={{ fontSize: '2rem' }}>
          New Orders
        </h4>
      </div>
      <Guests />
    </div>
  )
}

export default ManagerOrder

import React, { useState } from 'react'
import { storage } from '../../firebase'
import CsvDownload from 'react-json-to-csv'

const Create = () => {
  const [files, setfiles] = useState([])
  const [ok, setok] = useState([])

  var storageRef = storage.ref()

  const header = ['url']

  const handleFiles = (e) => {
    let arr = [...files]
    arr.push(e.target.files[0])
    setfiles(arr)
  }
  console.log(files)

  const uploadFile = async (e) => {
    let urlArray = []
    e.preventDefault()
    for (let i = 0; i < files.length; i++) {
      await storageRef.child(files[i].name).put(files[i])
      console.log('uploaded file' + files[i].name)
      urlArray.push({
        url: `https://firebasestorage.googleapis.com/v0/b/kaytee-international-38030.appspot.com/o/${encodeURI(
          files[i].name,
        )}?alt=media`,
      })
    }
    console.log(urlArray)
    setok(urlArray)
  }

  return (
    <div>
      <input type="file" onChange={handleFiles} multiple />
      <buttton onClick={uploadFile} className="btn btn-primary">
        Upload
      </buttton>
      <CsvDownload data={ok} />
    </div>
  )
}

export default Create

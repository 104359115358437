import React, { useEffect } from "react";
import BannerCard from "../Cards/BannerCard";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="gv-mb">
      <BannerCard bannerName={"Privacy Policy"} />
      <div className="container mt-3">
        <h3>Privay Policy</h3>
        <p>
          Kay Tee International and its affiliates (“Kay Tee International”) is
          committed to protecting your information. This Policy applies to Kay
          Tee International and any entities doing business under the Kay Tee
          International name worldwide, which collect, process and/or store
          information. This Privacy Policy explains how we collect and use
          information that you provide via our websites and apps or when you
          register for our services or our events. Please review this Policy
          before you give us any information. By using our websites registering
          for our services and events, you consent to the collection and use of
          your information as described in this Policy. <br /> <br />
          If you have any questions about our privacy practices or questions,
          requests or concerns regarding your information, please contact us via
          email at the following addresses:{" "}
          <a href="sales@kaytee.in">sales@kaytee.in</a> <br /> <br />
          Kay Tee International is the data controller and has its principal
          place of business at Kay Tee International, A103, Classic-Corner, 133
          Hill Road, Bandra (West), Mumbai 400 050.
        </p>
        <h2>Information We Collect</h2>
        <p>
          We collect information from various sources and methods as described
          below.
        </p>
        <h2>Sources Of Information</h2>
        <p>
          We collect information about you or your devices from the following
          sources: When you provide information to us when establishing or
          maintaining an account or otherwise receiving a service from us.
        </p>
        <h2>Types Of Information</h2>
        <p>
          From the sources listed above, we collect the following types of
          information: name, business email address, phone number, other content
          or information submitted via our websites or other channels URLs of
          webpages visited.
        </p>
        <h2>Information Collected Automatically</h2>
        <p>
          When you use our websites, we automatically collect and analyze
          certain information, which includes unique browser identifiers, IP
          addresses, browser and operating system information, device
          identifiers (such as the Apple IDFA or Android Advertising ID),
          geolocation and other device-specific information, Internet connection
          information, as well as details about your interactions with websites
          and apps.
        </p>
        <h2>Use of Cookies and Other Technologies</h2>
        <p>
          We and third parties use various technologies to collect information
          about your activities on the Kay Tee International website. <br />{" "}
          <br />
          Cookies and other local storage technologies. Cookies and local
          storage objects are files that contain data, such as unique
          identifiers, that we or a third party transfer to or read from your
          device to recognize the device, provide services, keep records, and
          conduct analytics and marketing. We and third parties use automated
          means to read or write information on your devices. These means
          include various types of cookies and other browser-based or
          plugin-based local storage (such as HTML5 storage or Flash-based
          storage). You may choose to set your web browser to refuse certain
          types of cookies or to alert you when certain types of cookies are
          being sent. Some browsers offer similar settings for HTML5 local
          storage and Flash storage. However, if you block or otherwise reject
          our cookies or local storage, certain websites (including our own
          Websites) may not function properly. <br /> <br /> Other technologies.
          We use other standard automated technologies, such as web beacons
          (also called clear GIFs or pixel tags) or similar technologies to
          deliver or communicate with cookies and track your use of our websites
          and/or apps. We include web beacons in email messages to track whether
          you open the messages, as well as for other uses as described below.
          In some cases, we facilitate the collection of information by
          advertising services administered by third parties. The ad services
          track your online activities over time by collecting information
          through automated means such as cookies, and they use this information
          to show you ads tailored to your individual interests or
          characteristics and/or based on prior visits to certain sites or apps,
          or other information we know, infer or have collected from you. <br />{" "}
          <br /> For example, we and the third-party vendors use first- and
          third-party cookies together, as well as other automated means and
          other data (such as the data described above) to: <br /> <br />
          Recognize you and your devices; <br /> <br /> Inform, optimize, and
          serve ads; and Report on our ad impressions, other uses of ad
          services, and interactions with these ad impressions and ad services
          (including how they are related to visits to specific sites or apps).
          To learn more about interest-based advertising generally, including
          how to opt out from the targeting of interest-based ads by some of our
          current ad service partners, CLICK HERE. For controls specific to
          advertising and analytics services offered by Google, click HERE, HERE
          and HERE. If you replace, change or upgrade your browser, or delete
          your cookies, you may need to use these opt-out tools again. We do not
          respond to browser-based do-not-track signals. <br /> <br /> Please
          refer to your mobile device manufacturer's website (or the website for
          its operating system) for instructions on how to use any additional
          privacy controls offered by your mobile operating system, such as
          privacy settings for device identifiers and geolocation.
        </p>
        <h2>How We Use Information We Collect</h2>
        <p>
          We use and disclose your information as follows: On the basis of
          implied or express consent, such as: To send you certain information,
          including marketing communications; To customize the content and
          advertising you see on our websites, across the Internet, and
          elsewhere, <br /> <br /> For our legitimate interests in conducting
          our business, such as: To fulfill your requests; To respond to your
          questions, concerns, or customer service inquiries; To otherwise
          communicate with you about your account; To carry out certain
          marketing activities; To conduct research and analysis, including
          surveys; For events: <br /> <br /> To enforce the legal terms that
          govern our websites and services; In connection with a business sale,
          merger, consolidation, change in control, transfer of substantial
          assets, bankruptcy, liquidation or reorganization (whether the
          recipient of data in these cases will handle the data pursuant to this
          Privacy Policy depends on applicable law and other factors); For
          pre-transaction review in relation to the transactions described in
          the preceding list item; or To create aggregated or anonymized data,
          which we may use and disclose without restriction; To comply with
          applicable laws, protect rights, safety and property, and respond to
          lawful requests from public authorities (such as disclosing data in
          appropriate situations for national security or law enforcement
          purposes); or To perform a service on behalf of a client who gives us
          the data. Some features of our websites, and services allow you to
          make data public or engage in other sharing. For example: <br />{" "}
          <br /> Registration on some of our websites will create a public
          profile (or a profile visible to other registered users), which
          includes information drawn from social networking accounts that you
          associate with the profile, such as name, photo and bio, as well as
          other information that you add. If you post content or otherwise
          participate in discussion forums or other social areas of our websites
          or apps, your participation (including the content you post, your
          name, and a link to your profile) may be shared across our websites
          and apps and in other public or private areas of the internet. Special
          uses and disclosures of automatically collected data are described in
          the preceding section. <br /> <br /> We generally retain information
          for so long as it is be relevant to the purposes above. Data may
          persist in copies made for backup and business continuity purposes for
          additional time. For any of the purposes above, we share information
          with other members of our corporate family. We also share your
          information with third-party service providers that perform services
          on our behalf. Some third parties’ embedded plugins or other automated
          technology on our websites, such as cookies or social sharing buttons,
          allow their operators to learn that you have visited or interacted
          with our websites, and they combine this information with other,
          identifiable information they have collected about your visits to
          other websites or online services. These third parties handle this
          information, and other information they directly collect through their
          content and plugins, pursuant to their own privacy policies.
        </p>
        <h2>Choices About Use Of Your Information</h2>
        <p>
          We believe it is important to give you choices about the use of your
          information. We will use your information as described in this Policy
          (or any other event- or service-specific Privacy Policy). If we want
          to use your information for a purpose not described in this Policy, we
          will first get your consent to do so.
        </p>
        <h2>Marketing Communications</h2>
        <p>
          We will respect your wishes not to receive marketing communications.
          You can change your marketing preferences by contacting us at the
          addresses. If you gave us your email address to receive marketing
          communications, you can opt out at any time by using the unsubscribe
          links or instructions included at the bottom of our emails. Please
          note that we will continue to send you service-related communications
          regardless of any opt-out request. We will not sell or share your
          information or information with third parties (other than our
          subsidiaries or affiliates) for their own promotional or marketing
          purposes unless you give us consent to do so and where permitted by
          applicable law. <br /> <br /> You also can exercise certain opt-out
          rights by following the instructions at the end of the “Information
          Collected Automatically” section above.
        </p>
        <h2>Information Security</h2>
        <p>
          We have implemented administrative, technical, and physical security
          measures to help prevent unauthorized access. Despite these measures,
          no data transmission over the Internet can be entirely secure, and we
          cannot and do not guarantee or warrant the security of any information
          you transmit via our websites or apps. <br /> <br />
          We have implemented administrative, technical, and physical security
          measures to help prevent unauthorized access. Despite these measures,
          no data transmission over the Internet can be entirely secure, and we
          cannot and do not guarantee or warrant the security of any information
          you transmit via our websites or apps. <br /> <br />
          We make efforts to restrict access to information to only those
          employees, contractors, and agents who need such access in order to
          operate, develop, improve, or deliver our programs, products, and
          services <br /> <br />
          Please note that you are responsible for maintaining the secrecy of
          your credentials used to access any account or service with AQUATEC
          INDIA, and you must report suspected unauthorized activity to us. You
          are responsible for activity conducted with your credentials. <br />{" "}
          <br />
          ACCESS TO YOUR INFORMATION/DATA SUBJECT RIGHTS In some cases, you may
          directly access your online profiles and other personal details and
          amend, update, add, or delete information yourself by logging into the
          relevant portions of our websites and apps. You can request access to
          information we hold about you, ask us to correct any information that
          may be inaccurate, and/or ask us to block or delete such information
          and object to the processing of information (within the limits of
          applicable law). In situations where we process your information on
          behalf of our clients, we may refer the request to the relevant client
          and cooperate with their handling of the request, subject to any
          special contractual arrangement with that client. To access, correct,
          or raise inquiries about your information in our possession, please
          email us at <a href="mailto:sales@kaytee.in">sales@kaytee.in</a>{" "}
          <br /> If you have any questions about this Policy or how AQUATEC
          INDIA processes your information, please email us at the relevant
          address above or contact us at: sales@aquatecindia.in. Any complaints
          about our adherence to the practices described in this Policy should
          first be directed to Aquatec India’s Data Protection Officer at
          sales@aquatecindia.in. We will attempt to answer your questions and
          satisfy your concerns in a timely and complete manner as soon as
          possible. If you are an EU data subject and your complaint is not
          resolved after discussing the matter with us, we will work to resolve
          the matter, free of charge to you, through cooperation with the
          relevant European privacy regulator. Formal complaints regarding our
          data protection practices and related actions regarding information
          from countries outside the EU may be addressed directly to the
          relevant data protection authority in your country.
        </p>
        <h2>Other Websites</h2>
        <p>
          To provide increased value to you, we provide links to other websites
          or resources that are not part of the products, programs, or services
          run by Kay Tee International. We do not control these websites or
          their privacy practices, and any information you provide to these
          sites is subject to the Privacy Policies of those sites and not this
          Policy.
        </p>
        <h2>Children</h2>
        <p>
          Our websites deliver business-related content and are specifically
          aimed at and designed for use by adults. We do not knowingly solicit
          or collect information from or about individuals under the age of 18
          years.
        </p>
        <h2>Changes to Privacy Policy</h2>
        <p>
          We may change this Privacy Policy to reflect changes in the law, our
          data handling practices or the features of our services or Website.
          The updated Privacy Policy will be posted on this website. If we make
          material changes to this Policy, we will provide a more prominent
          notice.
        </p>
        <h2>Discalimer</h2>
        <p>
          Kay Tee International has an ongoing policy of design, development and
          improvement thus reserves the right to modify specifications and
          prices without any prior notice. <br /> <br /> As a condition of your
          use of the Services, you will not use the Services for any purpose
          that is unlawful or prohibited by these terms, conditions, and
          notices. You may not use the Services in any manner that could damage,
          disable, overburden, or impair any AQUATEC INDIA’s server, or the
          network(s) connected to any AQUATEC INDIA server, or interfere with
          any other party's use and enjoyment of any Services. You may not
          attempt to gain unauthorized access to any Services, other accounts,
          computer systems or to any of the Services, through hacking, password
          mining or any other means. You may not obtain or attempt to obtain any
          materials or information through any means not intentionally made
          available through the Services
        </p>
      </div>
    </div>
  );
};

export default Privacy;

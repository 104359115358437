import React from 'react'

const BannerCard = (props) => {
  return (
    <div className="card bg-dark text-white">
      <img
        src={`${process.env.PUBLIC_URL}/images/b2.jpg`}
        height="140px"
        className="card-img ban-img"
        alt="..."
        style={{ objectFit: 'cover' }}
      />
      <div
        className="card-img-overlay banner-overlay"
        style={{ textAlign: 'center' }}
      >
        <h5 className="card-title headings banner-title">{props.bannerName}</h5>
      </div>
    </div>
  )
}

export default BannerCard

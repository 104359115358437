import React, { useState } from 'react'
import axios from 'axios'
import { API } from '../../backend'
import swal from 'sweetalert'

const AddGallery = () => {
  const [photo, setPhoto] = useState()
  const [loading, setLoading] = useState(false)

  const photoHandler = (e) => {
    setPhoto(e.target.files[0])
    console.log(photo)
  }

  const createloading = () => {
    if (loading) {
      return (
        <div>
          <center style={{ textAlign: 'center', width: '100%' }}>
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        </div>
      )
    }
  }

  const AddPhoto = async (e) => {
    setLoading(true)
    e.preventDefault()
    let formData = new FormData()
    formData.append('image', photo)
    await axios({
      method: 'post',
      url: `${API}/create/gallery/image`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        setLoading(false)
        swal('Photo has been added', '', 'success')
      })
      .catch((err) => {
        setLoading(false)
        swal('Could not upload photo', '', 'error')
      })
  }

  return (
    <div className="gv-mb">
      <div className="container mt-3">
        <div style={{ textAlign: 'center' }}>
          <h3>Add Photos to Gallery</h3>
        </div>
        {createloading()}
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Upload</span>
          </div>
          <div class="custom-file">
            <input
              onChange={photoHandler}
              type="file"
              class="custom-file-input"
              id="inputGroupFile01"
            />
            <label class="custom-file-label" for="inputGroupFile01">
              {photo === undefined ? 'Choose file' : photo.name}
            </label>
          </div>
        </div>
        <button onClick={AddPhoto} className="btn btn-dark">
          Upload
        </button>
      </div>
    </div>
  )
}

export default AddGallery

import React, { useContext, useState } from 'react'
import swal from 'sweetalert'
import { addItemToCart } from '../core/CartHelper'
import { AuthContext, Reloader } from '../../AuthProvider'
import axios from 'axios'
import { API } from '../../backend'
import { useHistory } from 'react-router-dom'

const ProductCard = (props) => {
  const r = useContext(Reloader)
  console.log(r)
  const history = useHistory()
  const getAlert = (productName) =>
    swal(`${productName.join(' ')}`, 'has been added to cart', 'success')

  const deleteThisProduct = async (e) => {
    e.preventDefault()
    const removedFirstPart = props.product.photoURL.replace(
      'https://firebasestorage.googleapis.com/v0/b/kaytee-international-38030.appspot.com/o/',
      '',
    )
    const fileName = removedFirstPart.replace('?alt=media', '')
    const send = {
      productName: props.product.name,
      originalname: decodeURI(fileName),
      doc_id: props.product.id,
    }
    console.log(send)
    await axios({
      method: 'post',
      url: `${API}/delete/product`,
      data: send,
      headers: { Accept: 'application/json' },
    })
      .then((response) => {
        if (response.status === 200) {
          r()
          swal(
            `${props.product.name.join(' ')} has been deleted!`,
            '',
            'success',
          )
          window.location.reload()
        } else {
          swal(
            'Could not delete the product',
            'Please try again later',
            'error',
          )
        }
      })
      .catch((err) => {
        swal('Could not delete the product', 'Please try again later', 'error')
      })
  }

  return (
    <div
      className="card mb-4 shadow"
      onClick={(e) => {
        e.preventDefault()
        history.push({
          pathname: `/product_detail/${props.product.name
            .join(',')
            .replace('/', ',')}`,
          state: {
            //product_name: props.product.name.replace('', '/'),
            product: props.product,
          },
        })
      }}
      style={{
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <img
        src={props.product.photoURL}
        className="img-fluid pt-4 pb-0 pr-4 pl-4"
        alt="..."
      />
      <div className="card-body p-0">
        <p
          className="card-title ml-2 font-weight-light"
          style={{ fontSize: '1rem' }}
        >
          {props.product.name.join(' ')}
        </p>
        <div class="d-flex bd-highlight">
          <div class="mr-auto  bd-highlight">
            <p className="font-italic ml-2" style={{ fontSize: '13px' }}>
              {props.product.product_id === '' ? 'Size  ' : 'SKU'}:
              <span className="phone-no">
                {props.product.product_id === ''
                  ? props.product.size
                  : props.product.product_id}
              </span>
              <br />{' '}
              {!props.product.description ||
              props.product.description === 'NA' ? (
                <></>
              ) : (
                `Finish: ${props.product.description}`
              )}
            </p>
          </div>
          <div style={{ position: 'absolute', bottom: '20px', right: '0px' }}>
            <div style={{ textAlign: 'center' }}>
              <a href="#" className=" mr-2 mb-0 mt-0 ">
                {props.deleteIcon ? (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        history.push({
                          pathname: '/admin/manage/product/update',
                          state: {
                            productID: props.product.id,
                          },
                        })
                      }}
                      className="btn-success btn"
                    >
                      Update
                    </button>

                    <svg
                      onClick={(e) => {
                        deleteThisProduct(e, props.product.name)
                      }}
                      width="2em"
                      height="2em"
                      viewBox="0 0 16 16"
                      class="bi bi-trash"
                      fill="red"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                      <path
                        fill-rule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                      />
                    </svg>
                  </>
                ) : (
                  <svg
                    onClick={(e) => {
                      e.preventDefault()
                      getAlert(props.product.name)
                      addItemToCart(props.product)
                      r()
                    }}
                    width="2.5em"
                    height="2.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-cart3 btn-dark btn"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCard

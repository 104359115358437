import React, { useState, useEffect } from "react";
import BannerCard from "../Cards/BannerCard";
import firebase from "firebase";

const Career = () => {
  const [vac, setVac] = useState([]);
  const [loading, setLoading] = useState(false);
  var db = firebase.firestore();
  useEffect(() => {
    preloadJobs();
    setLoading(true);
  }, []);

  const preloadJobs = async () => {
    let result = await db.collection("jobs").get();
    setVac(result.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setLoading(false);
  };
  return (
    <div>
      <BannerCard bannerName={"Career"} />
      <div className="gv-mb">
        <div className="container mt-2" style={{ overflow: "auto" }}>
          {loading ? (
            <center style={{ textAlign: "center", width: "100%" }}>
              <div className="spinner-border text-dark mt-4" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </center>
          ) : vac.length === 0 ? (
            <center
              style={{ textAlign: "center", width: "100%", overFlow: "auto" }}
            >
              <h3>No Job Vacancies right now!</h3>
            </center>
          ) : (
            <>
              <div style={{ textAlign: "center" }}>
                <h4>Contact us for more information</h4>
              </div>
              <div>
                <table class="table">
                  <thead class="bg-secondary text-white">
                    <tr style={{ textAlign: "center" }}>
                      <th scope="col">Job Title</th>
                      <th scope="col">Job Description</th>
                      <th scope="col">Vacancies</th>
                      <th scope="col">Experience</th>
                      <th scope="col">Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vac.map((v) => (
                      <tr style={{ textAlign: "center" }}>
                        <td>{v.title}</td>
                        <td>{v.description}</td>
                        <td>{v.vacancies}</td>
                        <td>{v.experience}</td>
                        <td>{v.location}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Career;

import React, { useState, useEffect } from 'react'
import firebase from '../../firebase'
import axios from 'axios'
import { API } from '../../backend'
import swal from 'sweetalert'
import { useHistory } from 'react-router-dom'

const DisplayBS = (props) => {
  var db = firebase.firestore()
  const history = useHistory()
  const [subcategories, setSubCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)

  const fetchSubCategories = async () => {
    const subcat = await db
      .collection('sub-categories')
      .where('brand', '==', props.brand.name)
      .get()
    setLoading(false)
    setSubCategories(subcat.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
  }

  const deleteSubCategory = async (e, subCategoryName) => {
    e.preventDefault()
    const brand = {
      subCategoryName: subCategoryName,
    }
    await axios({
      method: 'post',
      url: `${API}/delete/subcategory`,
      data: brand,
      headers: { Accept: 'application/json' },
    }).then((response) => {
      if (response.status === 200) {
        setReload(true)
        swal(
          `${subCategoryName.name} has been erased successfully`,
          '',
          'success',
        )
      } else {
        swal('Could not delete', '', 'warning')
      }
    })
  }

  const deleteBrand = async (e, brandName) => {
    e.preventDefault()
    console.log(brandName)
    const brand = {
      brandName: brandName,
    }
    await axios({
      method: 'post',
      url: `${API}/delete/brand`,
      data: brand,
      headers: { Accept: 'application/json' },
    }).then((response) => {
      if (response.status === 200) {
        setReload(!reload)
        swal(`${brandName} has been erased successfully`, '', 'success')
      } else {
        swal('Could not delete', '', 'warning')
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchSubCategories()
  }, [reload])

  console.log(subcategories)

  return (
    <div>
      {loading ? (
        <center style={{ textAlign: 'center', width: '100%' }}>
          <div className="spinner-border text-dark" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </center>
      ) : (
        <div className="card shadow">
          <div className="card-header bg-info text-white">
            {props.brand.name}{' '}
            <span style={{ float: 'right' }}>
              <button
                onClick={(e) => {
                  deleteBrand(e, props.brand.name)
                }}
                style={{ color: 'white', fontSize: '16px' }}
                className="bg-danger m-0 btn btn-danger text-white"
              >
                DELETE
              </button>
            </span>
          </div>
          <div className="list-group">
            {subcategories.length === 0 ? (
              <center>
                <li className="list-group-item">
                  No sub categories under this brand.
                </li>
              </center>
            ) : (
              subcategories.map((subcategory) => (
                <>
                  <li className="list-group-item">
                    {subcategory.name}{' '}
                    <span style={{ float: 'right' }}>
                      <a
                        onClick={() => {
                          localStorage.setItem(
                            'sub_category_display',
                            subcategory.name,
                          )
                          history.push({
                            pathname: `/admin/manage/${props.brand.name.replaceAll(
                              '/',
                              ',',
                            )}/${subcategory.name.replaceAll('/', ',')}`,
                            state: {
                              brandName: props.brand.name,
                              subCategoryName: subcategory.name,
                            },
                          })
                        }}
                        className="mr-2"
                        href=""
                      >
                        View Products
                      </a>
                      <button
                        onClick={(e) => {
                          deleteSubCategory(e, subcategory)
                        }}
                        style={{ color: 'white', fontSize: '12px' }}
                        className="bg-danger m-0 btn btn-danger text-white"
                      >
                        DELETE
                      </button>
                    </span>
                  </li>
                </>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default DisplayBS

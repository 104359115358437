import React from 'react'

const HomeTeam = () => {
  return (
    <div className="container mt-5 mb-4" style={{ textAlign: 'center' }}>
      <h3 className="headings">ABOUT KAY TEE INTERNATIONAL</h3>
      <br />
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <img
            src={`${process.env.PUBLIC_URL}/images/about.jpg`}
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="col-lg-8 mt-2 col-md-8 col-sm-12">
          <p>
            Kay Tee International is one of Mumbai’s largest channel partner of
            reputed Indian & International brands in Bathroom Fittings,
            Sanitaryware & Plumbing Fittings. With a humble beginning in 2007
            and a king size dream, we at Kay Tee aim to change the pattern and
            response to the customers. <br /> <br /> Kay Tee Group’s overriding
            objective is to move from the traditional role of reactionary
            marketing to a new strategic pro-active brand building role. The
            only thing that is constant is change, and India’s landscape is
            constantly changing and presenting new opportunities while
            simultaneously taking away others.
            <br /> <br />
            At Kay Tee, we are constantly striving towards a more efficient,
            cooperative and participative relationship with our customers and
            (as well as) suppliers. We will take a new untraditional approach of
            distribution throughout India. We provide a basket of products under
            one roof to meet any project or maintenance requirements. Our
            management philosophy is based on trust, responsibility and mutual
            respect. People who work with Kay Tee join our team because we have
            an environment that encourages creativity and achievement. Our sales
            team is a great blend of young knowledgeable people and seasoned
            veterans.
            <br /> <br />
            We believe with these core values and business expertise Kay Tee
            Group will continue on a path of profitable growth and will be
            recognized as one of the premier channel partners and sales &
            marketing company in India.
          </p>
        </div>
      </div>
    </div>
  )
}

export default HomeTeam

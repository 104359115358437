import React from 'react'
import BannerCard from '../Cards/BannerCard'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { useEffect, useState } from 'react'
import firebase from '../../firebase'

const Events = () => {
  const [events, setEvents] = useState([])
  const [loading, setloading] = useState(false)
  const db = firebase.firestore()

  const preload = async () => {
    let result = await db.collection('events').get()
    setEvents(result.docs.map((doc) => doc.data()))
    setloading(false)
  }

  useEffect(() => {
    preload()
    setloading(true)
  }, [])

  return (
    <div className="gv-mb">
      <BannerCard bannerName={'Events'} />
      <div className="container">
        {loading ? (
          <center style={{ textAlign: 'center', width: '100%' }}>
            <div className="spinner-border text-dark mt-4" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </center>
        ) : events.length === 0 ? (
          <center style={{ textAlign: 'center', width: '100%' }}>
            <h3>No Events</h3>
          </center>
        ) : (
          events.map((event) => (
            <VerticalTimeline layout="1-column">
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: '#9cd6f0',
                  color: '#',
                }}
                contentArrowStyle={{
                  borderRight: '7px solid  #9cd6f0',
                }}
                iconStyle={{ background: '#9cd6f0', color: '#fff' }}
              >
                <h3 className="vertical-timeline-element-title">
                  {event.name}
                </h3>

                <p>{event.date}</p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          ))
        )}
      </div>
    </div>
  )
}

export default Events

import React, { useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../AuthProvider'
import firebase from '../../firebase'
import { getDocById } from '../firebase/authFunctions'

const PrivateRoute = ({ component: Comp, ...rest }) => {
  var currentUser = useContext(AuthContext)

  // const getRole = async () => {
  //   let isRole = await getDocById(uid)
  //   console.log(isRole)
  // }

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser ? <Comp {...props} /> : <Redirect to="/" />
      }
    />
  )
}

export default PrivateRoute
